import React from 'react'
import { Component } from 'react';
import AddResume from '../views/Resume/Add';

const initialState = {
  fname: "",
  lname: "",
  email: "",
  phone: "",
  fileResume: undefined,
  savingResume: false,
  errors: {},
  showAddResume: false,
};

const activeDivL = () => {
  document.querySelector(".bbanner.bannerLeft").classList.add("active");
  document.querySelector(".bbanner:not(.bannerLeft)").classList.add("hidden");
  document.querySelector(".smallPop").classList.add("hiden");
  document.getElementById("openUpload").classList.add("hide");
  document.querySelector(".closeBanner").style.display = "none";
}

const activeDivR = () => {
  document.querySelector(".bbanner.bannerRight").classList.add("active");
  document.querySelector(".bbanner:not(.bannerRight)").classList.add("hidden");
  document.querySelector(".smallPop").classList.add("hiden");
  document.getElementById("openUpload").classList.add("hide");
  document.querySelector(".closeBanner").style.display = "none";
}

const backFunc = () => {
  document.querySelectorAll(".bbanner")[0].classList.remove("active", "hidden");
  document.querySelectorAll(".bbanner")[1].classList.remove("active", "hidden");
  document.querySelector(".smallPop").classList.remove("hiden");
  document.getElementById("openUpload").classList.remove("hide");
  document.querySelector(".closeBanner").style.display = 'block';
}

const sideRemover = () => {
  document.getElementById("smallpop").classList.remove("left");
  document.getElementById("smallpop").classList.remove("right");
}

const sideSelectorR = () => {
  document.getElementById("smallpop").classList.remove("left");
  document.getElementById("smallpop").classList.add("right");
}
const sideSelectorL = () => {
  document.getElementById("smallpop").classList.remove("right");
  document.getElementById("smallpop").classList.add("left");
}

const openUpload = () => {
  document.getElementById("smallpop").classList.add("active");
}

const closeUpload = () => {
  document.getElementById("smallpop").classList.remove("active");
}
const openBanner = () => {
  document.querySelector(".bannerPopupBg").style.display = 'block';
  document.body.classList.add("overflowHide");
}
const closeBanner = () => {
  document.querySelector(".bannerPopupBg").style.display = 'none';
  document.body.classList.remove("overflowHide");
}
class DivideBanner extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  showAddResume = () => {
    document.getElementById("smallpop").classList.remove("active");
    this.setState({
      showAddResume: true,
    });
  }

  hideAddResume = () => {
    this.setState({
      showAddResume: false,
    });
  }
  render() {
    const { t } = this.props;
    return (
      <>

        <button id="bannerButtonSide" onClick={openBanner}>Start Here</button>
        <div className="bannerPopupBg">
          <div className="bannerPopup">
            <section id="heroTwinBanner">
              <button className="closeBanner" onClick={closeBanner}><i className='fas fa-times'></i></button>
              <div className="bannerWrapper" onMouseOut={sideRemover}>
                <div className="bbanner bannerLeft" dtitle="left" onMouseOver={sideSelectorL}>
                  <img src="/img/join-banner.jpg" alt="Hire from Us" />
                  <div className="bannerText">
                    <div className="bannerContent">
                      <h3>Join our Talent Communinty</h3>
                      <p>ClockHash Technologies is an IT company providing services on “Team Augmentation” and “Offshore development”. We mainly focus on the latest technologies such as Cloud Technologies, DevOps, Full stack development, AI/ML and IoT systems.</p>
                      <button className="mainBtn viewBtn leftBtn" onClick={activeDivL}>View More</button>
                      <a className="back mainBtn" href="/login">Login/Signup</a>&nbsp;&nbsp;<button className="bannerBack altBtn back" onClick={backFunc}><i className="fas fa-arrow-left"></i><span>Back</span></button>
                    </div>
                  </div>
                </div>
                <div className="bbanner bannerRight" onMouseOver={sideSelectorR}>
                  <img src="/img/hire-banner.jpg" alt="Join Us" />
                  <div className="bannerText">
                    <div className="bannerContent">
                      <h3>Hire from our Talent Community</h3>
                      <p>ClockHash Technologies is an IT company providing services on “Team Augmentation” and “Offshore development”. We mainly focus on the latest technologies such as Cloud Technologies, DevOps, Full stack development, AI/ML and IoT systems.</p>
                      <button className="mainBtn viewBtn rightBtn" onClick={activeDivR}>View More</button>
                      <a className="back mainBtn" href="/login">Login/Signup</a>&nbsp;&nbsp;<button className="bannerBack altBtn back" onClick={backFunc}><i className="fas fa-arrow-left"></i><span>Back</span></button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="smallPop" id="smallpop">
                <i className="fas fa-paperclip"></i>
                <button id="closeUpload" onClick={closeUpload}><i className="fas fa-times"></i></button>
                <h4>Upload</h4>
                <div className="d-flex">
                  <div className="w50"><button onClick={this.showAddResume}>Resume</button></div>
                  <div className="w50"><a href="/login">Requirement</a></div>
                </div>
              </div>
              <button id="openUpload" onClick={openUpload}><i class="fas fa-upload"></i> Upload</button>

            </section>
          </div>
        </div>
        <AddResume
                show={this.state.showAddResume}
                onClose={this.hideAddResume}
            />
      </>
    )
  }
};

export default DivideBanner