import React from 'react';
import http from '../../httpService';
import SuccessField from './SuccessField';

export default class ForgetPasswordField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: undefined,
            token: undefined,
            password: undefined,
            password_confirmation: undefined,
            errors: {
                email: undefined,
                password: undefined,
                password_confirmation: undefined
            },
            successmessage: undefined
        };
    }

    handleInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value.trim(),
        });
    }

    Validatetoken = async(token) => {
        console.log(token);

    }

    componentDidMount() {
        let url_string = window.location.href;
        let url = new URL(url_string);
        let url_email = url.searchParams.get("email");
        let url_token = url.searchParams.get("token");
        if (url_email && url_token) {
            this.setState({
                email: url_email,
                token: url_token
            })

            this.Validatetoken(url_token);

            for (var i = 0; i < document.querySelectorAll(".tabBtn").length; i++) {
                document.querySelectorAll(".tabBtn")[i].style.display = "none";
            }
            document.querySelector(".tabDiv.active").classList.remove("active");
            document.getElementById('forgetpassword').classList.add("active");
            document.querySelector(".tabBtn.forgetpassword").style.display = "block";
            document.getElementById("resetpasswordfield").style.display = "block";
            document.getElementById("forgetpasswordfield").style.display = "none";
        }

    }

    doForgetPassword = async (event) => {
        event.preventDefault();
        
        let isValid = true;
        if (this.state.email == undefined || this.state.email == "") {
            isValid = false;
            this.setState({
                errors: {
                    email: "Please enter your email."
                }
            })
        }

        if (isValid) {
            try {

                const response = await http.post('api/forget', {
                    email: this.state.email,
                });

                if (response && response.data) {
                    let tabbtns = document.querySelector('.glassCard .tabBtns');
                    let forgetpasswordfield = document.querySelector('#topInnerPage #forgetpasswordfield');
                    tabbtns.style.display = 'none';
                    forgetpasswordfield.style.display = 'none';
                    window.scrollTo(0, 0);

                    this.setState({
                        errors: {
                            email: "",
                        },
                        successmessage: "Link has been send your Mail"
                    })
                }

            } catch (excpt) {

            }
        }
    }

    doResetPassword = async (event) => {
        event.preventDefault();

        let isValid = true;

        if (this.state.password == undefined) {
            isValid = false;
            this.setState({
                errors: {
                    password: "Please enter your password."
                }
            })

        }
        if (this.state.password_confirmation != this.state.password) {
            isValid = false;
            this.setState({
                errors: {
                    password_confirmation: "Please enter your Same password."
                }
            })
        }

        if (isValid) {
            try {
                const response = await http.post('api/reset-password', {
                    email: this.state.email,
                    password: this.state.password,
                    password_confirmation: this.state.password_confirmation,
                    token: this.state.token
                });

                if (response && response.data) {
                    console.log(response.data);
                    let tabbtns = document.querySelector('.glassCard .tabBtns');
                    let resetpasswordfield = document.querySelector('#topInnerPage #resetpasswordfield');
                    tabbtns.style.display = 'none';
                    resetpasswordfield.style.display = 'none';
                    window.scrollTo(0, 0);

                    this.setState({
                        errors: {
                            password: "",
                            password_confirmation: ""
                        },
                        successmessage: "Password Updated"
                    })
                }

            } catch (excpt) {

            }
        }
    }

    render() {
        return (
            <>
                <div>
                    <form action="" className="zForm white" onSubmit={this.doForgetPassword} id="forgetpasswordfield" style={{ 'display': 'block' }}>
                        <div className="inpGroup">
                            <label htmlFor="email">Email</label>
                            <input type="text" name="email" id='email' placeholder='johndoe@email.com' onChange={this.handleInputChange} />
                            <div className="text-danger">{this.state.errors.email}</div>
                        </div>
                        <div className="bottomSection">
                            <button type="submit" className='mainBtn'>Submit</button>
                        </div>
                    </form>
                    <form action="" className="zForm white" onSubmit={this.doResetPassword} id="resetpasswordfield" style={{ 'display': 'none' }}>
                        <div className="inpGroup">
                            <label htmlFor="password">Password</label>
                            <input type="password" name="password" id='password' placeholder='Your Password' onChange={this.handleInputChange} />
                            <div className="text-danger">{this.state.errors.password}</div>
                        </div>
                        <div className="inpGroup">
                            <label htmlFor="password_confirmation">Confirm Password</label>
                            <input type="password" name="password_confirmation" id='password_confirmation' placeholder='Same Password' onChange={this.handleInputChange} />
                            <div className="text-danger">{this.state.errors.password_confirmation}</div>
                        </div>
                        <div className="bottomSection">
                            <button type="submit" className='mainBtn'>Update</button>
                        </div>
                    </form>                    
                    { this.state.successmessage ? <SuccessField message={this.state.successmessage}/> : "" }
                </div>
            </>
        );
    }
}