import React from 'react';  
import PartnerSlider from '../../components/PartnerSlider';

const WhyUs = () => {

    return (
        <main>
            <section id="topInnerPage" className="sectionPad whyUs">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="mainTitle">
                                <h2>Why Clockhash</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-sm-6 d-grid">
                            <div className="glassCard">
                                <img src="/img/why/trusted.svg" alt="" />
                                <h4>Reliable Professionals</h4>
                                <p>Most of our professionals are seasoned professionals who can swiftly ramp up, contribute as task force members, and collaborate with you to save onboarding cost.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 d-grid">
                            <div className="glassCard">
                                <img src="/img/why/right-fit.svg" alt="" />
                                <h4>The Perfect Match </h4>
                                <p>We have an uncanny ability to find the perfect match for you. Move forward with your new employee on a no-risk trial basis, only paying if you're happy</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 d-grid">
                            <div className="glassCard">
                                <img src="/img/why/scale.svg" alt="" />
                                <h4>Scale to Next-level</h4>
                                <p>No conditions attached, hire in under 30 days and scale your team up to the next level as needed.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 d-grid">
                            <div className="glassCard">
                                <img src="/img/why/seamless.svg" alt="" />
                                <h4>Effortless Onboarding</h4>
                                <p>Invoices, transactions, and NDAs are all handled by us. Allow us to handle the bandwidth while you concentrate on developing outstanding products.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 d-grid">
                            <div className="glassCard">
                                <img src="/img/why/flexible.svg" alt="" />
                                <h4>Adaptable Agreements</h4>
                                <p>Choose an involvement type that best meets your needs – hourly, part-time, or full-time — and modify it at any time.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 d-grid">
                            <div className="glassCard">
                                <img src="/img/why/expert.svg" alt="" />
                                <h4>Accurately Match Talents</h4>
                                <p>Concentrate on your assignment while your dedicated account executive and experienced talent matcher assist you.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="hireTop">
                <div className="container sectionPad">
                    <div className="row">
                        <div className="col-12">
                            <div className="mainTitle">
                                <h2>Hire the Top Talents through Clockhash</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-8 text-center">
                            <p>Clockhash is a marketplace for top Dancer developers, engineers, programmers, coders, architects, and consultants. Top companies and start-ups choose Clockhash Dancer freelancers for their mission-critical software projects.</p>
                            <a href="/login" className="mainBtn white">Hire a Top Talent Now</a><br/>
                            <small>No-Risk Trial, Pay Only If Satisfied.</small>
                        </div>
                    </div>
                </div>
                <div className="container"><hr /></div>
                <div className="container sectionPad" id="partner">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Our Expertise</h2>
                            <PartnerSlider/>
                        </div>
                    </div>
                </div>
            </section>
            <section id="screening" className="sectionPad">
                <div className="container">
                    <div className="row">
                        <div className="mainTitle">
                            <h2>The Clockhash Screening Process</h2>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-8 text-center">
                            <p>All candidates to the Clockhash network must go through a screening process that evaluates their domain knowledge, professionalism, and communication skills. The entire screening procedure can be completed within 4 weeks to complete.</p>
                        </div>
                    </div>
                    <div id="top3percent">
                        <div className="row">
                            <div className="col-lg-2 col-md-6 d-grid align-items-center justify-content-lg-center">
                                <div className="icon">
                                    <img src="/img/why/screening/lang-personality.svg" alt="Attitude and Communication" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 d-grid">
                                <div className="glassCard percent">
                                    <h4>40%</h4>
                                    <p>of applicants pass</p>
                                </div>
                            </div>
                            <div className="col-lg-8 d-grid mt-3 mb-5 mt-lg-0 mb-lg-0">
                                <div className="glassCard desc">
                                    <h3>Attitude and Communication</h3>
                                    <p>A detailed English language and communication interview assessment is the first stage in the screening process. We also look at personality factors, seeking for applicants who are enthusiastic about their work and thoroughly engaged in it.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-2 col-md-6 d-grid align-items-center justify-content-lg-center">
                                <div className="icon">
                                    <img src="/img/why/screening/skill.svg" alt="Extensive Skill Analysis" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 d-grid">
                                <div className="glassCard percent">
                                    <h4>20%</h4>
                                    <p>of applicants pass</p>
                                </div>
                            </div>
                            <div className="col-lg-8 d-grid mt-3 mb-5 mt-lg-0 mb-lg-0">
                                <div className="glassCard desc">
                                    <h3>Extensive Skill Analysis</h3>
                                    <p>We also employ a range of tests to assess each candidate's technical knowledge and problem solving skills. Each Clockhash network candidate is an expert in their subject, and we typically only advance candidates who have performed well in this round.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-2 col-md-6 d-grid align-items-center justify-content-lg-center">
                                <div className="icon">
                                    <img src="/img/why/screening/live.svg" alt="Screening Testing" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 d-grid">
                                <div className="glassCard percent">
                                    <h4>10%</h4>
                                    <p>of applicants pass</p>
                                </div>
                            </div>
                            <div className="col-lg-8 d-grid mt-3 mb-5 mt-lg-0 mb-lg-0">
                                <div className="glassCard desc">
                                    <h3>Screening &amp; Testing</h3>
                                    <p>Clockhash reviewers who are highly qualified and experienced in their functional domain interview each candidate. Our screeners conduct live tasks to assess problem-solving abilities, experience depth, communication skills, and creativity.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-2 col-md-6 d-grid align-items-center justify-content-lg-center">
                                <div className="icon">
                                    <img src="/img/why/screening/excellence.svg" alt="Test Projects" />
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 d-grid">
                                <div className="glassCard percent">
                                    <h4>7%</h4>
                                    <p>of applicants pass</p>
                                </div>
                            </div>
                            <div className="col-lg-8 d-grid mt-3 mb-5 mt-lg-0 mb-lg-0">
                                <div className="glassCard desc">
                                    <h3>Continued Excellence</h3>
                                    <p>When dealing with clients, members of the Clockhash community have a proven track record of success. We focus on the best for talent and the best for clients as a quality-first firm, and this approach pervades every engagement and executed project.<br/>
                                        As a Clockhash client, you'll have access to the best of the best talents, all of whom have passed our rigorous screening process.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
};

export default WhyUs;
