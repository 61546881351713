import React, { useState, useEffect } from 'react';
import http from '../../../httpService';
import { useParams } from 'react-router-dom';

export const BlogPage = () => {
    const { slug } = useParams();
    let [blogDetails, setBlogDetails] = useState(null);

    useEffect(() => {
        slugfunction();
    },[])

    const slugfunction = async() => {
        const formData = new FormData();
        formData.append('slug', slug);
        try {
            let response = await http.post("api/blog-slug", formData);
            if (response.data) {
                console.log(response.data.blog);
                setBlogDetails(response.data.blog);
            }
        } catch (exception) {
            if (exception.response && exception.response.data && exception.response.data.errors) {
                console.log(exception.response.data.errors);
            }
        }
        
    }
    return (
        <main>
            <section className="sectionPad blogDetail" id="topInnerPage">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>{blogDetails ? blogDetails.title : null}</h1>
                            {blogDetails ? <img src={blogDetails.image} alt="blog" /> : null}
                            {blogDetails ? <p><div dangerouslySetInnerHTML={{ __html: blogDetails.body }}></div></p> : null}
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}
