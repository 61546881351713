import React,{Component} from 'react';
import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
const options = {
  loop: true,
  autoplay: true,
  items: 2,
  margin: 24,
  responsive: {
    0:{
      items: 1
    },
    768:{
      items: 2
    }
  }
}
export class Testimonials extends Component{
    render(){
        return (
            <>
                <ReactOwlCarousel className='owl-theme' {...options}>
                <div className="glassCard">
                          <p>As one of the biggest players in the European hosting domain, we had concerns on how our team will be able to work with remote resources who are working 4.30hours behind our time zone. But after working with the team for 8 months, ClockHash Technologies proved there is no barrier on timezone, language and skills on the resources provided by them. We highly recommend ClockHash Technologies to companies who wants to scale up quickly.</p>
                          <div className="userDetails">
                            <img src="/img/user.svg" alt="User" />
                            <div className="textSection">
                              <h4>Anonymous</h4>
                              <p>Clockhash Customer</p>
                            </div>
                          </div>
                        </div>
                        <div className="glassCard">
                          <p>We are extremely happy with the resource augment service provided by ClockHash Technologies. The team helped to resolve our resource scarcity with zero overhead of resource acquisition and management. They are also the best DevOps team that we have ever worked with. We found that the team values their Customers so much that they have never compromised on the quality of resources onboarded to our team.</p>
                          <div className="userDetails">
                            <img src="/img/arjun.jpeg" alt="img" />
                            <div className="textSection">
                              <h4>Arjun Varma</h4>
                              <p>Alcodex Technologies</p>
                            </div>
                          </div>
                        </div>
                        <div className="glassCard">
                          <p>ClockHash Technologies has brought tremendous value to our new IoT Home Automation business at Oswin Controls. From the concept stage to the production, launch and now revenue stage. ClockHash has helped design our software/IoT Hardware platform on AWS from the ground up.</p>
                          <div className="userDetails">
                            <img src="/img/Ceby.jpg" alt="img" />
                            <div className="textSection">
                              <h4>Ceby Thankachan</h4>
                              <p>Founder, Oswin Controls, Dubai, UAE</p>
                            </div>
                          </div>
                        </div>
                        <div className="glassCard">
                          <p>For more than six months, we've been working alongside Clockhash technologies. We are really pleased with the contract resources they have offered. I would strongly suggest Clockhash Technologies to my colleagues and friends.</p>
                          <div className="userDetails">
                            <img src="/img/harold.jpg" alt="img" />
                            <div className="textSection">
                              <h4>Harold Paul E</h4>
                              <p>Mananasol India Pvt Ltd</p>
                            </div>
                          </div>
                        </div>
                </ReactOwlCarousel>
            </>
          )
    }
}

export default Testimonials