import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import {
    WhatsappShareButton
} from "react-share";
import http from '../../httpService';
import AddResume from '../Resume/Add';
import Popupz from '../../components/Popupz';

function SuccessPopup(props) {
    return (
        <div>
            <Popupz title="Vacancy Applied" id="uploadResume" className={props.show ? 'active' : ''} onClose={props.onClose}>
                <div className="text-center">
                    {props.icon}
                    <p>{props.message}</p>
                    <a href="/" className='mainBtn'>Go to Home</a>
                </div>
            </Popupz>
        </div>
    )
}

export default function Details(props) {
    let [userDetails, setUserDetails] = useState(null);
    let { vacancySlug } = useParams();
    const [vacancy, setVacancy] = useState(null);
    const [showAddResume, setShowAddResume] = useState(false);
    let [showPopup, setShowPopup] = useState(false);
    let [showmessage, setShowmessage] = useState('');
    let [showIcon, setShowIcon] = useState(<svg className='img-fluid mb-2' xmlns="http://www.w3.org/2000/svg" width="113.625" height="113.133" viewBox="0 0 113.625 113.133">
        <circle class="st0" cx="56.3" cy="56.6" r="55" style={{ 'fill': 'none', 'stroke': '#FF0000', 'stroke-width': '3', 'stroke-linecap': 'round', 'stroke-linejoin': 'round' }} />
        <line class="st0" x1="37.4" y1="37.6" x2="75.2" y2="75.5" style={{ 'fill': 'none', 'stroke': '#FF0000', 'stroke-width': '3', 'stroke-linecap': 'round', 'stroke-linejoin': 'round' }} />
        <line class="st0" x1="37.4" y1="75.5" x2="75.2" y2="37.6" style={{ 'fill': 'none', 'stroke': '#FF0000', 'stroke-width': '3', 'stroke-linecap': 'round', 'stroke-linejoin': 'round' }} />
    </svg>);
    useEffect(() => {
        if (vacancySlug) {

            http.get("api/vacancy", {
                params: {
                    slug: vacancySlug
                }
            }).then((response) => {
                if (response.data && response.data.vacancy) {
                    setVacancy(response.data.vacancy);
                }
            });
        }
        http.get('api/edit-profile')
            .then((response) => {
                setUserDetails(response.data);
            });
    }, []);

    const handleShowAddResume = async () => {
        let authData = localStorage.getItem('AuthData');
        if (authData) {
            authData = JSON.parse(authData);
            if (authData) {
                if (userDetails) {
                    // let user_id = userDetails.user_id;
                    // let resumer_id = userDetails['userResume'][0].id;
                    const formData = new FormData();
                    // formData.append('user_id', user_id);
                    // formData.append('resumer_id', resumer_id);
                    formData.append('vacancy_id', vacancy.id);
                    try {
                        let response = await http.post("api/vacancy-insert", formData);
                        console.log(response.data);
                        if (response.data.status) {
                            setShowIcon(
                                <svg className='img-fluid mb-2' xmlns="http://www.w3.org/2000/svg" width="113.625" height="113.133" viewBox="0 0 113.625 113.133">
                                    <g id="Icon_feather-check-circle" data-name="Icon feather-check-circle" transform="translate(-1.49 -1.43)" stroke='#00ff2a'>
                                        <path id="Path_1341" data-name="Path 1341" d="M112.994,52.962v5.06A55,55,0,1,1,80.381,7.755" transform="translate(0 0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                        <path id="Path_1342" data-name="Path 1342" d="M85,6,30,61.052l-16.5-16.5" transform="translate(27.998 8.024)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                    </g>
                                </svg>
                            );
                        }
                        setShowmessage(response.data.message);
                        setShowPopup(true);
                    } catch (exception) {
                        if (exception.response) {
                            //setSavingResume(false);
                            console.log(exception.response);
                        }
                    }
                }
            }
        } else {
            setShowAddResume(true);
        }
    }

    return (
        <main>
            <section id="topInnerPage" className="sectionPad career">

                {
                    vacancy &&

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="mainTitle">
                                    <h2>{vacancy.title}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="jobCard">
                                    <a href="/careers" className="backBtn"><i className="bi bi-arrow-left"></i>Back</a>
                                    <span className="jobId ms-0">ID: {vacancy.code}</span>
                                    <p className="place">{vacancy.location}</p>
                                    {/* <p className="remote">This job provide remote work option</p> */}

                                    <p className="price my-3"><i className="bi bi-coin"></i> {vacancy.pay_range}</p>
                                    <div className="textContent">

                                        {
                                            vacancy.description && <>
                                                <h3>Description</h3>
                                                <div
                                                    className="mb-2"
                                                    dangerouslySetInnerHTML={{
                                                        __html: vacancy.description
                                                    }}
                                                >
                                                </div>
                                            </>
                                        }

                                        {
                                            vacancy.basic_qualification && <>
                                                <h3>Basic Qualification</h3>
                                                <div
                                                    className="mb-2"
                                                    dangerouslySetInnerHTML={{
                                                        __html: vacancy.basic_qualification
                                                    }}
                                                ></div>
                                            </>
                                        }

                                        {
                                            vacancy.preferred_skills && <>
                                                <h3>Skill Required</h3>
                                                <div
                                                    className="mb-2"
                                                    dangerouslySetInnerHTML={{
                                                        __html: vacancy.preferred_skills
                                                    }}
                                                ></div>
                                            </>
                                        }

                                        {
                                            vacancy.desired_skills && <>
                                                <h3>Nice to have skills</h3>
                                                <div
                                                    className="mb-2"
                                                    dangerouslySetInnerHTML={{
                                                        __html: vacancy.desired_skills
                                                    }}
                                                ></div>
                                            </>
                                        }

                                    </div>
                                    <div className="bottom">
                                        <a href="#" className="mainBtn" onClick={handleShowAddResume}>Apply Now</a>
                                        <ul className="shareList">
                                            <li>Share:</li>
                                            <li><WhatsappShareButton url={window.location.href}><i className="fab fa-whatsapp"></i></WhatsappShareButton></li>
                                            {/* <li><a href=""><i className="fab fa-instagram"></i></a></li>
                                            <li><a href=""><i className="fab fa-linkedin-in"></i></a></li>
                                            <li><a href=""><i className="fab fa-facebook-f"></i></a></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <AddResume
                            show={showAddResume}
                            vacancyId={vacancy.id}
                            onClose={() => setShowAddResume(false)}
                        />
                        <SuccessPopup
                            icon={showIcon}
                            message={showmessage}
                            show={showPopup}
                            vacancyId={vacancy.id}
                            onClose={() => setShowPopup(false)}
                        />
                    </div>
                }
            </section>
        </main>
    );
};