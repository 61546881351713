import React from 'react';

export default function SuccessField(props) {
    return (
        <div>
            <div className="text-center">
                <svg className='img-fluid mb-2' xmlns="http://www.w3.org/2000/svg" width="113.625" height="113.133" viewBox="0 0 113.625 113.133">
                    <g id="Icon_feather-check-circle" data-name="Icon feather-check-circle" transform="translate(-1.49 -1.43)" stroke='#00ff2a'>
                        <path id="Path_1341" data-name="Path 1341" d="M112.994,52.962v5.06A55,55,0,1,1,80.381,7.755" transform="translate(0 0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                        <path id="Path_1342" data-name="Path 1342" d="M85,6,30,61.052l-16.5-16.5" transform="translate(27.998 8.024)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                    </g>
                </svg>
                <p>{props.message}</p>
                <a href="/" className='mainBtn'>Go to Home</a>
            </div>
        </div>
    )
}