import React from 'react';  
import {
	BrowserRouter as Router,
	Routes,
	Route,
} from 'react-router-dom';
import ReactGA from 'react-ga';
import './App.css';
import Layout from './components/Layout';



function App() {
    
    ReactGA.initialize('G-HNE39NW8XT');
    
    return (
        <Router>
            <Routes>
                {/* <Route path="/login" element={<Login />}></Route> */}
                <Route path="*" element={<Layout />}></Route>
            </Routes>
        </Router>
    );
  
}

export default App;
