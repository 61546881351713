import React from 'react'

const ErrorPage = () => {
  return (
    <main>
                <section className="sectionPad error404" id="topInnerPage">
                    <div className="container">
                        <div className="row justify-content-center text-center">
                            <div className="col-md-4">
                                
                        <div className="glassCard px-5 pb-3">
                                <img src="/img/404.svg" alt="404" className='img-fluid mb-3'/>
                                <a href="/" className="mainBtn">Go to Home</a>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>
            </main>
  )
}

export default ErrorPage