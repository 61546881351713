import React from 'react';  
import { useTranslation } from "react-i18next";

const LangList = () => {

    const { i18n } = useTranslation();

    const langListToggle = ()=>{
        var element = document.getElementById('langList');
    if(element.style.display === "none"){
        element.style.display = "block";
    }else{
        element.style.display = "none";
    }

    }
    const langSelect = (e)=>{
        const elems = document.querySelectorAll(".lang");
        for(var i=0; i< elems.length; i++){
                elems[i].classList.remove("active");
        }
        e.target.className = "lang active";
        const selectedLanguage = e.target.getAttribute('langname');

        localStorage.setItem('language', selectedLanguage);

        document.getElementById('sltd').innerHTML = selectedLanguage;

        i18n.changeLanguage(selectedLanguage);
    }

    const selectedLanguage = localStorage.getItem('language') ? localStorage.getItem('language')  : 'en';

    return (
        <div className="langSelector" onClick={langListToggle}>
            <div className="langSelected"><span id="sltd">{ selectedLanguage }</span> <i className="bi bi-caret-down-fill"></i></div>
            <ul className="langList" id="langList">
                <li onClick={langSelect} langname="en" className="lang active"><img src="/img/flags/united-kingdom.svg" alt="English" /> English</li>
                <li onClick={langSelect} langname="de" className="lang"><img src="/img/flags/germany.svg" alt="German" /> German</li>
                <li onClick={langSelect} langname="nl" className="lang"><img src="/img/flags/netherlands.svg" alt="Dutch" /> Dutch</li>
                <li onClick={langSelect} langname="fr" className="lang"><img src="/img/flags/france.svg" alt="French" /> French</li>
            </ul>
        </div>
  )
}

export default LangList