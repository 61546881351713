import React from 'react';

export const CheckClock = (props) => {
  return (
    <div className="clockCheck me-2">
        <input type="checkbox" name={props.name} id={props.id} value={props.value} onChange={props.onChange} checked={props.checked}/>
        <label htmlFor={props.id}><span className='iconCheckz'><i class="fas fa-check"></i></span> <span className="labelText">{props.label}</span></label>
    </div>
  )
}
