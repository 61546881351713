import axios from "axios";

const qs = require("qs");
const config = require("./config");

let axiosHeaders = {};
let loginData = localStorage.getItem("AuthData");

if (loginData) {
    loginData = JSON.parse(loginData);
    
    axiosHeaders = {'Authorization' : loginData.auth_token_type + ' ' + loginData.auth_token};
}

const http = axios.create({
  baseURL: config.APIURL + ":" + config.PORT + "/",
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      encode: false,
    });
  },
  headers: axiosHeaders,
});

http.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
 
    return response;
  },
  (error) => {


    if (error.response && error.response.status === 500) {
      console.error("Database Connection Failed");
    } else {
      console.error(error);
    }
    return Promise.reject(error);
  }
);

export default http;
