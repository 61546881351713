import i18n from "i18next";
import { initReactI18next } from "react-i18next";


// Importing translation files

import translationEN from "./locales/en/translation.json";
import translationDE from "./locales/de/translation.json";
import translationNL from "./locales/nl/translation.json";
import translationFR from "./locales/fr/translation.json";


//Creating object with the variables of imported translation files
const resources = {
    en: {
        translation: translationEN,
    },
    de: {
        translation: translationDE,
    },
    nl: {
        translation: translationNL,
    },
    fr: {
        translation: translationFR,
    },
};

//i18N Initialization

const selectedLanguage = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: selectedLanguage, //default language
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;