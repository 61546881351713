import React, { Component } from "react"
// import { useTranslation } from "react-i18next";
import { withTranslation } from "react-i18next"
import DeveloperSlider from "../../components/DeveloperSlider"
import Testimonials from "../../components/Testimonials"
import PartnerSlider from "../../components/PartnerSlider"
import Popupz from "../../components/Popupz"
import http from "../../httpService"
import AddResume from "../Resume/Add"
import BlogList from "../../components/BlogList"
import { Helmet } from "react-helmet"

const initialState = {
  fname: "",
  lname: "",
  email: "",
  phone: "",
  fileResume: undefined,
  savingResume: false,
  errors: {},
  showAddResume: false,
}

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = initialState
  }

  componentDidMount() {
    const noscriptElement = document.createElement("noscript")
    const iframeElement = document.createElement("iframe")
    iframeElement.src =
      "https://www.googletagmanager.com/ns.html?id=GTM-TVWXFJBS"
    iframeElement.height = "0"
    iframeElement.width = "0"
    iframeElement.style.display = "none"
    iframeElement.style.visibility = "hidden"
    noscriptElement.appendChild(iframeElement)
    document.body.firstElementChild.insertAdjacentElement(
      "afterend",
      noscriptElement
    )
  }

  showAddResume = () => {
    document.getElementById("smallpop").classList.remove("active")
    this.setState({
      showAddResume: true,
    })
  }

  hideAddResume = () => {
    this.setState({
      showAddResume: false,
    })
  }

  render() {
    const { t } = this.props
    return (
      <main>
        <Helmet>
          <script>
            {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-TVWXFJBS');
            `}
          </script>
        </Helmet>
        <section id="heroSection">
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src="/img/banner.jpg"
                  className="d-block w-100"
                  alt="..."
                />
              </div>
              <div className="carousel-item">
                <img
                  src="/img/banner2.jpg"
                  className="d-block w-100"
                  alt="..."
                />
              </div>
              <div className="carousel-item">
                <img
                  src="/img/banner3.jpg"
                  className="d-block w-100"
                  alt="..."
                />
              </div>
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <i className="fas fa-chevron-circle-left"></i>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <i className="fas fa-chevron-circle-right"></i>
              <span className="visually-hidden">Next</span>
            </button>
            <div className="bannerText">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <h1>
                      {t("Powerful Technology Solutions With ClockHash.")}
                    </h1>
                    <p>
                      {t(
                        "ClockHash Technologies is an IT company providing services on “Team Augmentation” and “Offshore development”. We mainly focus on the latest technologies such as Cloud Technologies, DevOps, Full stack development, AI/ML and IoT systems."
                      )}
                    </p>
                    <hr />
                    <h4>{t("Join our Talent Community")}</h4>
                    <button
                      type="button"
                      onClick={this.showAddResume}
                      className="mainBtn"
                    >
                      {t("Upload Resume")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ul className="socialList">
            <li>
              <a
                target="_blank"
                href="https://www.facebook.com/ClockHash-Technologies-100511448836722"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.instagram.com/clockhash/">
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/clockhash/"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            </li>
          </ul>
          <a href="#topTalents" class="box">
            <span></span>
            <span></span>
            <span></span>
          </a>
        </section>
        <section id="second">
          <section id="topTalents" className="sectionPad">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="mainTitle">
                    <h2>{t("What do We Master")}</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 d-grid">
                  <div className="glassCard">
                    <img
                      src="/img/services/cloud.svg"
                      alt="cloud_administration"
                    />
                    <h4>{t("Cloud Administration")}</h4>
                    <ul>
                      <li>AWS, Azure, GCP</li>
                      <li>Kubernetes, Docker</li>
                      <li>{t("Infrastructure Management")}</li>
                      <li>CICD implementation</li>
                      <li>{t("Monitoring & logging")}</li>
                      <li>{t("Database administration")}</li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 d-grid">
                  <div className="glassCard">
                    <img
                      src="/img/services/web_based_application.svg"
                      alt="web_based_application"
                    />
                    <h4>{t("Full Stack Development")}</h4>
                    <ul>
                      <li>Backend: Node.JS, PHP, GraphQL, SQL</li>
                      <li>
                        Frontend: React.JS, Typescript, Angular, Redux, HTML,
                        CSS
                      </li>
                      <li>Automation Testing: Selenium, TestNG</li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 d-grid">
                  <div className="glassCard">
                    <img
                      src="/img/services/embedded-system.svg"
                      alt="embedded-system"
                    />
                    <h4>Embedded Systems and IoT</h4>
                    <ul>
                      <li>Firmware Development</li>
                      <li>Linux Device Driver Development</li>
                      <li>
                        IoT Application Development:
                        <br />
                        AWS IoT, Azure IoT
                      </li>
                      <li>
                        Major Areas of Expertise:
                        <ul>
                          <li>Automotive</li>
                          <li>Energy Monitoring and Management</li>
                          <li>Networking</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                    <div className="col-lg-4 col-sm-6 d-grid">
                    <div className="glassCard card-1">
                        <img src="/img/services/cloud.svg" alt="cloud_administration" />
                        <h4>{ t('Cloud Services') }</h4>
                        <p>{ t('ClockHash has a highly skilled team which helps to provide the best cloud and infrastructure service in Europe. The hierarchy of team based on experience suits to meet our customer\'s requirements.') }</p>
                    </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 d-grid">
                    <div className="glassCard card-2">
                        <img src="/img/services/devops.svg" alt="devops"/>
                        <h4>{ t('DevOps') }</h4>
                        <p>{ t("We're a well known DevOps Consulting Company with consultants in Europe. Meet our talented team of DevOps engineers.") }</p>
                    </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 d-grid">
                    <div className="glassCard card-3">
                        <img src="/img/services/embedded-system.svg" alt="embedded-system"/>
                        <h4>{ t('Embedded System') }</h4>
                        <p>{ t('Provider of best hardware and software solutions for the Embedded Systems Market.') }</p>
                    </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 d-grid">
                    <div className="glassCard card-4">
                        <img src="/img/services/iot.svg" alt="iot"/>
                        <h4>{ t('IoT') }</h4>
                        <p>{ t('Integrating advanced IoT technologies and cloud services in Europe, to form the base of both smart home solutions and other industrial solutions. Make your digital life with us.') }</p>
                    </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 d-grid">
                    <div className="glassCard card-5">
                        <img src="/img/services/mobile_application.svg" alt="mobile_application"/>
                        <h4>{ t('Mobile Applications') }</h4>
                        <p>{ t("We bring to the table world-class product and UX design, as well as tech talent in iOS, Android and hybrid development. As there's no one-size-fits-all, we assemble a tailor-made, cross-functional team of experts that fits your exact need.") }</p>
                    </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 d-grid">
                    <div className="glassCard card-6">
                        <img src="/img/services/web_based_application.svg" alt="web_based_application"/>
                        <h4>{ t('Web Based Applications') }</h4>
                        <p>{ t("Here at ClockHash you will meet ambitious web app developers who are looking for new challenging tasks. We can tackle projects of any scale and complexity, and have enough expertise to integrate the client's software and hardware within a new web application.") }</p>
                    </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 d-grid">
                    <div className="glassCard card-7">
                        <img src="/img/services/qa_testing.svg" alt="qa_testing"/>
                        <h4>{ t('QA Testing') }</h4>
                        <p>{ t('Using an impressive lineup of QA testing technology, we help you release the highest-quality product possible.') }</p>
                    </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 d-grid">
                    <div className="glassCard card-8">
                        <img src="/img/services/cyber_security.svg" alt="cyber_security"/>
                        <h4>{ t('Cyber Security') }</h4>
                        <p>{ t('Our expertise Engineers help to design and implement secure network solutions designed to defend against hackers, cyber-attacks, and other persistent threats. They also engage in testing and monitoring of those systems, continually making sure that all of the systems, defenses are up to date and working correctly.') }</p>
                    </div>
                    </div>
                    <div className="col-lg-4 col-sm-6 d-grid">
                    <div className="glassCard card-9">
                        <img src="/img/services/onsite_consultancy.svg" alt="onsite_consultancy"/>
                        <h4>{ t('Onsite Consultancy') }</h4>
                        <p>{ t('Preferred when augmentation of your team for short periods are required. Our engineers work as part of your team. They report to project managers in your team. Both long term as well as short term options available.') }</p>
                    </div>
                    </div>
                </div> */}
            </div>
          </section>
          <section id="wayWorking" className="sectionPad">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="mainTitle">
                    <h2>{t("Our way of Working")}</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="glassCard">
                    <div className="img-section">
                      <img
                        src="/img/digital-core-capabilities.svg"
                        alt="talent"
                      />
                    </div>
                    <div className="textSection">
                      <h3>{t("Digital Core Capabilities")}</h3>
                      <p>
                        {t(
                          "Build vital capabilities to deliver digital outcomes."
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="glassCard">
                    <div className="img-section">
                      <img src="/img/digital-operating.svg" alt="talent" />
                    </div>
                    <div className="textSection">
                      <h3>{t("Digital Operating Models")}</h3>
                      <p>
                        {t("Adopt accelerators to evolve your way of working.")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="glassCard">
                    <div className="img-section">
                      <img src="/img/talent.svg" alt="talent" />
                    </div>
                    <div className="textSection">
                      <h3>{t("Empowering Talent Transformations")}</h3>
                      <p>
                        {t(
                          "Embrace the talent revolution to remain relevant in the future"
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="glassCard">
                    <div className="img-section">
                      <img src="/img/next.svg" alt="talent" />
                    </div>
                    <div className="textSection">
                      <h3>{t("The next")}</h3>
                      <p>
                        {t(
                          "We bring you powerful advantages to navigate your digital transformation"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
        <section id="augmentation" className="sectionPad">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mainTitle left">
                  <h2>{t("Team Augmentation Process")}</h2>
                </div>
              </div>
            </div>
            <div className="row position-relative">
              <div className="col-lg-6">
                <p>
                  {t(
                    "Quickly assemble the teams you need, exactly when you need them."
                  )}
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <div className="glassCard">
                      <div className="icon">
                        <i className="fas fa-tachometer-alt"></i>
                      </div>
                      <h3>{t("Hire Quickly")}</h3>
                      <p>
                        {t(
                          "Hire in under 48 hours. Scale up or down, no strings attached. We offer flexible engagements from hourly to full-time."
                        )}
                      </p>
                    </div>

                    <div className="glassCard">
                      <div className="icon">
                        <i className="fas fa-upload"></i>
                      </div>
                      <h3>{t("Leading the Future of Work")}</h3>
                      <p>
                        {t(
                          "Our network is ready for tomorrow's business challenges by embracing advanced and specialized skills including blockchain and AI."
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="glassCard">
                      <div className="icon">
                        <i className="fas fa-clipboard-check"></i>
                      </div>
                      <h3>{t("The Top 3%")}</h3>
                      <p>
                        {t(
                          "Every applicant to the Clockhash network is rigorously tested and vetted. Our highly selective process leads to a 98% trial-to-hire success rate."
                        )}
                      </p>
                    </div>
                    <div className="glassCard">
                      <div className="icon">
                        <i className="fas fa-chart-bar"></i>
                      </div>
                      <h3>{t("A Level Above")}</h3>
                      <p>
                        {t(
                          "Every single remote resource in our global network embodies the highest levels of integrity, professionalism, and communication."
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="paw50">
                  <DeveloperSlider />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="ipSection" className="smallSectionPad">
          <div className="container">
            <div className="row">
              <h2>{t("Your IPs Safe with Us")}</h2>
              <p>
                {t(
                  "Keep complete control and retention of your team's intellectual property and invention rights."
                )}
              </p>
              {/* <a href="/" className="mainBtn m-auto">Login</a> */}
            </div>
          </div>
          {/* <img src="/img/deel-logo.svg" alt="deel logo" className="deel"/> */}
        </section>
        <section id="offShore" className="sectionPad">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mainTitle left dark">
                  <h2>{t("Offshore development")}</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5">
                <p>
                  {t(
                    "Build the right product with ClockHash which delivers high value to users. With our experience in marketing mass consumer products, we help you to build your dream product at the lowest cost and quick market release."
                  )}
                </p>
                <div className="row">
                  <div className="col-lg-5 col-md-6">
                    <div className="whiteCard">
                      <i className="fas fa-smile"></i>
                      <h3>10</h3>
                      <p>{t("Happy Clients")}</p>
                    </div>
                    <div className="whiteCard">
                      <i className="fas fa-clock"></i>
                      <h3>2</h3>
                      <p>{t("Years of experience")}</p>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6 pt-md-5">
                    <div className="whiteCard">
                      <i className="fas fa-book"></i>
                      <h3>11</h3>
                      <p>{t("Projects Completed")} </p>
                    </div>
                    <div className="whiteCard">
                      <i className="fas fa-award"></i>
                      <h3>15</h3>
                      <p>{t("Appreciations")}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 d-grid">
                <img
                  src="/img/offshore-development.jpg"
                  alt="offshore"
                  className="img-fill"
                />
              </div>
            </div>
          </div>
        </section>
        <section id="howToHire" className="sectionPad">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mainTitle">
                  <h2>{t("How to Hire Talents through Clockhash")}</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="glassCard" data-num="1">
                  <h3>{t("Talk to One of Our Industry Experts")}</h3>
                  <p>
                    {t(
                      "Our engineering team will deep-dive with you to fully understand your technical needs, culture, and strategic route."
                    )}
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="glassCard" data-num="2">
                  <h3>{t("Work With Hand-Selected Talent")}</h3>
                  <p>
                    {t(
                      "Within two to four business days after first reaching out to Clockhash, we will match you with the right Talents. We deliver high-quality work, on time and at the velocity you require to meet your time-sensitive deadlines."
                    )}
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="glassCard" data-num="3">
                  <h3>{t("The Right Fit, Guaranteed")}</h3>
                  <p>
                    {t(
                      "We will be with you every step of the onboarding process to ensure a seamless process and team integration."
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="joinTalent" className="sectionPad">
          <div className="container">
            <div className="row">
              <h2>{t("Join our Talent Community")}</h2>
              <a href="/login" className="mainBtn m-auto">
                Login
              </a>
            </div>
          </div>
        </section>
        <section id="testimonial">
          <div className="sectionPad">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="mainTitle">
                    <h2>{t("Testimonials")}</h2>
                  </div>
                </div>
                <div className="col-md-12">
                  <Testimonials />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="blog" className="sectionPad">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mainTitle">
                  <h2>{t("Latest Blogs")}</h2>
                </div>
              </div>
            </div>
          </div>
          <BlogList />
          <div className="text-center pb-3">
            <a href="/blog" className="mainBtn">
              View All
            </a>
          </div>
        </section>
        <section id="joinUs">
          <img src="/img/divider.svg" alt="divider" className="divider" />
          <div className="sectionPad">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="mainTitle left">
                    <h2>{t("Partner with Us")}</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <h3>{t("Trial Period as a part of Hiring process")}</h3>
                  <p>
                    {t(
                      "We offer 3 days trial for clients  to prove  our candidates are a good match"
                    )}
                  </p>
                  <a href="/login" className="mainBtn">
                    Join Now
                  </a>
                </div>
                <div className="col-lg-9 col-md-6 text-end">
                  <img
                    src="/img/logo-big.svg"
                    alt="logo"
                    className="img-fluid logo-bg"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="bookAppointment" className="sectionPad">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mainTitle dark">
                  <h2>{t("Book Appointment")}</h2>
                </div>
                <div className="text-center">
                  <p>
                    {t(
                      "Are you looking to reach us? Then what are you waiting for book appointments through"
                    )}
                  </p>
                  <img
                    src="/img/calendar-logo.svg"
                    alt="Calendar.com"
                    className="img-fluid"
                  />
                  <br />
                  <a
                    href="https://calendly.com/clockhash-talk-to-us/talk-to-me"
                    target="_blank"
                    className="mainBtn big"
                  >
                    Book Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="partner" className="sectionPad">
          <div className="container">
            <div className="col-md-12">
              <h2>{t("Our Expertise")}</h2>
              <PartnerSlider />
            </div>
          </div>
        </section>

        <AddResume
          show={this.state.showAddResume}
          onClose={this.hideAddResume}
        />
      </main>
    )
  }
}

export default withTranslation()(Home)
