import React, { useState, useEffect } from 'react';
import http from '../../httpService';
import { useParams } from "react-router-dom";
import Popupz from '../../components/Popupz';
import { useNavigate } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { CheckClock } from '../../components/CheckClock';

function SuccessPopup(props) {
    let navigate = useNavigate();
    const CreatePost = () => {
        let path = `/my-account-employer`;
        navigate(path);
    }
    return (
        <>
            <Popupz title="Posted Job" id="uploadResume" className={props.show ? 'active' : ''} onClose={props.onClose}>
                <div className="text-center">
                    <svg className='img-fluid mb-2' xmlns="http://www.w3.org/2000/svg" width="113.625" height="113.133" viewBox="0 0 113.625 113.133">
                        <g id="Icon_feather-check-circle" data-name="Icon feather-check-circle" transform="translate(-1.49 -1.43)" stroke='#00ff2a'>
                            <path id="Path_1341" data-name="Path 1341" d="M112.994,52.962v5.06A55,55,0,1,1,80.381,7.755" transform="translate(0 0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                            <path id="Path_1342" data-name="Path 1342" d="M85,6,30,61.052l-16.5-16.5" transform="translate(27.998 8.024)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                        </g>
                    </svg>
                    <p>{props.message}</p>
                    <a onClick={CreatePost} style={{ 'cursor': 'pointer' }} className='mainBtn'>Go to My Account</a>
                </div>
            </Popupz>
        </>
    )
}

const EditJob = () => {

    let [userId, setUserId] = useState('');
    let [title, setTitle] = useState('');
    let [salary, setSalary] = useState('');
    let [vacancy, setVacancy] = useState('');
    let [description, setDescription] = useState('');
    const [attachmentKey, setAttachmentKey] = useState(1);
    let [attachment, setAttachment] = useState('');
    let [skills, setSkills] = useState([]);
    let [Hybrid, setHybrid] = useState(false);
    let [Remote, setRemote] = useState(false);
    let [Onsite, setOnsite] = useState(false);
    let [employmentType, setEmploymentType] = useState('');
    let [AttachmentDownload, SetAttachmentDownload] = useState('');
    let [showPopup, setShowPopup] = useState(false);
    let [showmessage, setShowmessage] = useState('');
    let [postid, setPostid] = useState('');
    let [errors, setErrors] = useState({
        title: undefined,
        salary: undefined,
        vacancy: undefined,
        description: undefined
    })
    let { id } = useParams();
    let userskills = [];

    useEffect(() => {
        http.get('api/edit-profile')
            .then((response) => {
                setUserId(response.data.user_id);
            });
        window.scrollTo(0, 0);
        getPostData();
        pagepermission();
    }, [])

    const pagepermission = async () => {
        let authData = localStorage.getItem('AuthData');
        if (authData) {
            try {
                const response = await http.get('api/userrole');
                if (response.data.is_hirer) {
                    console.log("Hirer");
                } else if (response.data.is_job_seeker) {
                    window.location.href = "/my-account";
                } else {
                    window.location.href = "/my-account-employer";
                }
            } catch (exception) {

            }
        } else {
            window.location.href = "/";
        }
    }

    const AttachmentHandleChange = (event, value) => setSkills(value);
    const HybridHandleChange = () => setHybrid(!Hybrid);
    const RemoteHandleChange = () => setRemote(!Remote);
    const OnsiteHandleChange = () => setOnsite(!Onsite);

    const getPostData = async () => {
        setPostid(id);
        const formData = new FormData();
        formData.append('post_id', id);
        try {
            let response = await http.post("api/getpost", formData);
            if (response.data.post) {
                if (response.data.post.attachment) {
                    let attachment = JSON.parse(response.data.post.attachment);
                    let attachmentpath = response.data.url + attachment[0].download_link;
                    SetAttachmentDownload(<a href={attachmentpath} target="_blank" className='mainBtn'>Download</a>);
                }
                let skilldata = JSON.parse(response.data.post.skills);
                skilldata.map(item => userskills.push(item));

                setPostid(response.data.post.id);
                setTitle(response.data.post.title);
                setSalary(response.data.post.salary);
                setVacancy(response.data.post.vacancy);
                setDescription(response.data.post.description);
                setEmploymentType(response.data.post.employment_type);
                let WorkMode = JSON.parse(response.data.post.work_mode);
                setHybrid(WorkMode.hybrid);
                setRemote(WorkMode.remote);
                setOnsite(WorkMode.onsite);
            }
        } catch (exception) {
            if (exception.response && exception.response.data && exception.response.data.errors) {
                console.log(exception.response.data.errors);
            }
        }
    }
    const PostSave = async () => {
        let WorkMode = { hybrid: Hybrid, remote: Remote, onsite: Onsite }
        const formData = new FormData();
        formData.append('post_id', postid);
        formData.append('user_id', userId);
        formData.append('title', title);
        formData.append('salary', salary);
        formData.append('vacancy', vacancy);
        formData.append('description', description);
        formData.append('attachment', attachment);
        formData.append('skills', JSON.stringify(skills));
        formData.append('workmode', JSON.stringify(WorkMode));
        formData.append('employmenttype', employmentType);
        try {
            let response = await http.post("api/updatepost", formData);
            if (response.data.status && response.data.message) {
                setPostid(response.data.postid);
                setShowPopup(true);
                setShowmessage(response.data.message);
            }
        } catch (exception) {
            if (exception.response && exception.response.data && exception.response.data.errors) {
                console.log(exception.response.data.errors);
                setErrors(exception.response.data.errors);
            }
        }
    }

    const deleteAccount = () => {
        document.getElementById("delete-account").classList.add("active");
    }

    const DeleteAccountClose = () => {
        document.getElementById("delete-account").classList.remove("active");
    }

    const DeletePost = async () => {
        const formData = new FormData();
        formData.append('post_id', postid);
        formData.append('user_id', userId);
        try {
            let response = await http.post("api/deletepost", formData);
            if (response.data.message) {
                setShowPopup(true);
                setShowmessage(response.data.message);
            }
        } catch (exception) {
            if (exception.response && exception.response.data && exception.response.data.errors) {
                console.log(exception.response.data.errors);
            }
        }
    }

        /*upload resume file input name showing function*/
        ; (function (document, window, index) {
            var inputs = document.querySelectorAll('.inputfile');
            Array.prototype.forEach.call(inputs, function (input) {
                var label = input.nextElementSibling,
                    labelVal = label.innerHTML;

                input.addEventListener('change', function (e) {
                    var fileName = '';
                    if (this.files && this.files.length > 1)
                        fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
                    else
                        fileName = e.target.value.split('\\').pop();

                    if (fileName)
                        label.querySelector('span').innerHTML = fileName;
                    else
                        label.innerHTML = labelVal;
                });

                // Firefox bug fix
                input.addEventListener('focus', function () { input.classList.add('has-focus'); });
                input.addEventListener('blur', function () { input.classList.remove('has-focus'); });
            });
        }(document, window, 0));
    /*upload resume file input name showing function*/
    return (
        <>
            <main>
                <section className="sectionPad myAccountCandidate" id="topInnerPage">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="mainTitle">
                                    <h2>Edit Job</h2>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="glassCard userIntro">
                                    <form className="zForm white">
                                        <div className="inpGroup">
                                            <label htmlFor="jobtitle">Job Title*</label>
                                            <input type="text" name="jobtitle" id="jobtitle" value={title} placeholder='Job Title' required onChange={e => setTitle(e.target.value)} />
                                            <div className="text-danger">{errors.title}</div>
                                        </div>

                                        <div className="inpGroup">
                                            <label htmlFor="salary">Allocated budget per month(in USD)*</label>
                                            <div className='salaryCurrency'>
                                                <input type="text" name="salary" id="salary" value={salary} placeholder='Allocated budget per month*' required onChange={e => setSalary(e.target.value)} />
                                            </div>
                                            <div className="text-danger">{errors.salary}</div>
                                        </div>

                                        <div className="inpGroup">
                                            <label htmlFor="salary">Add Attachment</label>
                                            <div class="fileInpWrapper">
                                                <input type="file" name="fileResume" id="fileResume" accept=".pdf,.docx" class="fileinp inputfile inputfile-1" data-multiple-caption="{count} files selected" key={attachmentKey} onChange={e => setAttachment(e.target.files[0])} />
                                                <label class="file-inp-l" for="fileResume"><span>&nbsp; Choose File</span><i className="bi bi-cloud-arrow-up"></i></label>
                                                {AttachmentDownload}
                                                <div className="text-danger">{errors.attachment}</div>
                                            </div>
                                        </div>
                                        <div className="inpGroup">
                                            <label htmlFor="vacancy">No. of vacancies*</label>
                                            <input type="number" name="vacancy" id="vacancy" value={vacancy} placeholder='Vacancies' required onChange={e => setVacancy(e.target.value)} />
                                        </div>
                                        <div className="text-danger">{errors.vacancy}</div>
                                        <div className="inpGroup">
                                            <label htmlFor="vacancy">Skills</label>
                                            <Autocomplete
                                                tag={skills}
                                                className='skillsTags'
                                                multiple
                                                id="tags-standard"
                                                options={skillsoption}
                                                getOptionLabel={(option) => option.title}
                                                defaultValue={userskills}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="standard"
                                                        label="Skills"
                                                        placeholder="Skills"
                                                    />
                                                )}
                                                onChange={AttachmentHandleChange}
                                            />
                                        </div>
                                        <div className="inpGroup">
                                            <label htmlFor="work-mode">Work Mode</label>
                                            <div className="d-flex">
                                                <CheckClock label="Hybrid" id="hybrid" name="work-mode-1" value='hybrid' onChange={HybridHandleChange} checked={Hybrid} />
                                                <CheckClock label="Remote" id="remote" name="work-mode-2" value='remote' onChange={RemoteHandleChange} checked={Remote} />
                                                <CheckClock label="On Site" id="onsite" name="work-mode-3" value='onsite' onChange={OnsiteHandleChange} checked={Onsite} />
                                            </div>
                                        </div>
                                        <div className="inpGroup">
                                            <label htmlFor="employment-type">Type of Employment</label>
                                            <select name="employment-type" id="employement-type" onChange={(e) => setEmploymentType(e.target.value)} value={employmentType}>
                                                <option value="" selected disabled hidden>Select Option</option>
                                                <option value="fulltime">Contract Full time</option>
                                                <option value="parttime">Contract Part time</option>
                                                <option value="tohire">Contract to Hire</option>
                                                <option value="offshore">Offshore Development</option>
                                            </select>
                                        </div>
                                        <div className="inpGroup pb-4">
                                            <label htmlFor="jobtitle">Job Description*</label>
                                            <textarea name="jondesc" id="jobdesc" rows="5" placeholder='Job Description' value={description} onChange={e => setDescription(e.target.value)} />
                                        </div>
                                        <div className="text-danger">{errors.description}</div>
                                        <button type='button' onClick={PostSave} className='mainBtn me-2'>Save Job</button>
                                        <button type='button' onClick={deleteAccount} className='altBtn danger'>Delete Job</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Popupz title="Delete Job" id="delete-account">
                    <p>Do you want to delete the post?</p>
                    <button type='button' className="altBtn me-1" onClick={DeletePost}>Yes</button>
                    <button type='button' className='mainBtn ms-1' onClick={DeleteAccountClose}>No</button>
                </Popupz>
            </main>
            <SuccessPopup
                message={showmessage}
                show={showPopup}
                vacancyId={postid}
                onClose={() => setShowPopup(false)}
            />
        </>
    )
}
const skillsoption = [
    { title: 'PHP' },
    { title: 'Javascript' },
    { title: 'HTML' },
    { title: 'CSS' },
    { title: 'React' }
];

export default EditJob