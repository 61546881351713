import React, { useEffect, useState } from 'react';
import { useParams , useNavigate } from "react-router-dom";
import ReactGA from 'react-ga';
import http from '../../httpService';
import Popupz from '../../components/Popupz';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export default function AddResume(props) {
    let navigate = useNavigate();
    const [phoneCountryCode, setPhoneCountryCode] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [resume, setResume] = useState('');
    const [resumeKey, setResumeKey] = useState(1);
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [vacancies, setVacancies] = useState('');
    const [vacanciesid, setVacanciesid] = useState('');
    const [othervacancies, setOthervacancies] = useState('');
    const [savingResume, setSavingResume] = useState(false);
    const [successresult, setSuccessResult] = useState('block');
    const [responseMessage, setResponseMessage] = useState('');
    const [responseStatus, setResponseStatus] = useState('');
    const [errors, setErrors] = useState({
        name: undefined,
        email: undefined,
        resume: undefined,
        jobtitle: undefined
    });

    useEffect(async () => {
        const response = await http.get("api/vacancies");

        if (response.data && response.data.vacancies) {
            let vacanciesdata = response.data.vacancies;
            let listItems = vacanciesdata.map((item) =>
                <option value={item.id}>{item.title}</option>
            );
            setVacancies(listItems);
        }
    }, [])

    const addResume = async () => {
        setSavingResume(true);
        let errors = {};
        let isValid = true;

        if (firstName == '') {
            isValid = false;
            errors.name = "Please enter your name.";
        }

        if (email == '') {
            isValid = false;
            errors.email = "Please enter your email.";
        } else {
            let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(email)) {
                isValid = false;
                errors.email = "Please enter valid email address.";
            }
        }

        if (vacanciesid == '') {
            isValid = false;
            errors.jobtitle = "Choose The Job Title.";
        }

        if (!resume) {
            isValid = false;
            errors.resume = "Please upload your resume.";
        } else {
            var extension = resume.name.split('.').pop();
            if (!["pdf", "docx", "doc"].includes(extension.toString().toLowerCase())) {
                isValid = false;
                errors.resume = "Only PDF/DOCX/DOC files are allowed.";
            }
        }

        if (isValid) {

            const formData = new FormData();
            formData.append('first_name', firstName);
            formData.append('last_name', lastName);
            formData.append('email', email);
            formData.append('phone', phoneCountryCode + phone);
            formData.append('resume', resume);
            if(vacanciesid){
                formData.append('vacancy_id', vacanciesid);
            }
            if(vacanciesid == 'other'){
                formData.append('vacancy_id', '');
            }
            if(othervacancies){
                formData.append('othervacancies', othervacancies);
            }
            if (props.vacancyId) {
                formData.append('vacancy_id', props.vacancyId);
            }
            
            try {
                let response = await http.post("api/resume", formData);
                // props.onClose();
                // console.log(response);
                setErrors(errors);
                setFirstName('');
                setLastName('');
                setEmail('');
                setPhone('');
                setResume('');
                setResumeKey(resumeKey + 1);
                setSavingResume(false); 

                ResumeSuccessFunc();
                setSuccessResult('none');
                setResponseMessage(response.data.message)
                setResponseStatus(response.data.status)
                ReactGA.event({
                    category: "Submit Resume",
                    action: "User submitted resume",
                });

                /*.then(response => {
                    props.onClose();
                    setErrors(errors);
                    setFirstName('');
                    setLastName('');
                    setEmail('');
                    setPhone('');
                    setResume('');
                    setResumeKey(resumeKey + 1);
                    setSavingResume(false);
                });*/
            } catch (exception) {

                if (exception.response && exception.response.data && exception.response.data.errors) {

                    setErrors(exception.response.data.errors);
                    setSavingResume(false);
                }
            }

        } else {
            setErrors(errors);
            setSavingResume(false);
        }
    }

    const ResumeSuccessFunc = () => {
        document.getElementById("resumeSuccess").classList.add("active");
    }

    const JobTitle = (e) => {
        if (e.target.value == "other")
            document.getElementById("jobOtherDiv").classList.remove("d-none");
        else
            document.getElementById("jobOtherDiv").classList.add("d-none");
            setVacanciesid(e.target.value);
    }

      /*upload resume file input name showing function*/
      ;( function ( document, window, index )
      {
          var inputs = document.querySelectorAll( '.inputfile' );
          Array.prototype.forEach.call( inputs, function( input )
          {
              var label	 = input.nextElementSibling,
                  labelVal = label.innerHTML;
      
              input.addEventListener( 'change', function( e )
              {
                  var fileName = '';
                  if( this.files && this.files.length > 1 )
                      fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
                  else
                      fileName = e.target.value.split( '\\' ).pop();
      
                  if( fileName )
                      label.querySelector( 'span' ).innerHTML = fileName;
                  else
                      label.innerHTML = labelVal;
              });
      
              // Firefox bug fix
              input.addEventListener( 'focus', function(){ input.classList.add( 'has-focus' ); });
              input.addEventListener( 'blur', function(){ input.classList.remove( 'has-focus' ); });
          });
      }( document, window, 0 ));
      /*upload resume file input name showing function*/

    return <Popupz title="Upload Resume" id="uploadResume" className={props.show ? 'active' : ''} onClose={props.onClose}>
        <div className='mb-3'><p>If you want watch the progress on your Resume feel free to</p><a href="/login" className="altBtn mb-3">Login/Sign Up</a><br/><hr/></div>
        <form action="" className='zForm' style={{display: successresult}}>
            <div className="inpGroup">
                <label htmlFor="fname">First Name</label>
                <input name="fname" type="text" id='fname' value={firstName} placeholder='John' onChange={e => setFirstName(e.target.value)} />
            </div>
            <div className="text-danger">{errors.name}</div>
            <div className="inpGroup">
                <label htmlFor="lname">Last Name</label>
                <input name="lname" type="text" id='lname' value={lastName} placeholder='Doe' onChange={e => setLastName(e.target.value)} />
            </div>
            <div className="inpGroup">
                <label htmlFor="eMail">Email</label>
                <input name="email" type="email" id='email' value={email} placeholder='johndoe@gmail' onChange={e => setEmail(e.target.value)} />
            </div>
            <div className="text-danger">{errors.email}</div>
            <div className="inpGroup">
                <label htmlFor="phone">Phone</label>
                <PhoneInput placeholder="+91 XXXXX-XXXXX" name="phone" country={'in'}/>
                {/* <div className="phoneNumber">
                    <select name="countryCode" id="countryCode" onChange={e => setPhoneCountryCode(e.target.value)}>
                        <option value="+91">+91</option>
                        <option value="+49">+49</option>
                        <option value="+31">+31</option>
                        <option value="+33">+33</option>
                    </select>
                    <input type="tel" name="phone" id='phone' value={phone} placeholder='Phone' onChange={e => setPhone(e.target.value)} />
                </div> */}
            </div>
            <div className="inpGroup mb-3">
                <label htmlFor="fileResume">Upload Resume</label>
                {/* <input name="fileResume" type="file" accept=".pdf,.docx" key={resumeKey} id='fileResume' onChange={e => setResume(e.target.files[0])} /> */}
                <div class="fileInpWrapper">
                                                <input type="file" name="fileResume" id="fileResume" accept=".pdf,.docx" key={resumeKey} class="fileinp inputfile inputfile-1" data-multiple-caption="{count} files selected"  onChange={e => setResume(e.target.files[0])} />
					                            <label class="file-inp-l" for="fileResume"><span>&nbsp; Choose File</span><i className="bi bi-cloud-arrow-up"></i></label>
				                            </div>
                <small>File must be of type .pdf or .docx and less than 4mb.</small>
            </div>
            <div className="text-danger">{errors.resume}</div>
            <div className="inpGroup mb-3">
                <label htmlFor="">Job Title</label>
                <select name="job-title" id="job-title" onChange={JobTitle}>
                    <option value="" selected disabled hidden>Select Job Title</option>
                    {vacancies}
                    <option value="other">Other</option>
                </select>
            </div>
            <div className="inpGroup mb-3 d-none" id='jobOtherDiv'>
                <label htmlFor="jobOther">Your Job Title</label>
                <input type="text" id='jobOther' placeholder='Type a Job title' onChange={e => setOthervacancies(e.target.value)} />
            </div>
            <div className="text-danger">{errors.jobtitle}</div>
            <button type="button" className='mainBtn' onClick={addResume} disabled={savingResume}>
                {savingResume &&
                    <div className="spinner-grow spinner-grow-sm mr-5" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                }
                Submit
            </button>
            {/* <button className="altBtn ms-2 mb-3" type='button' onClick={ResumeSuccessFunc}>Show Success Msg</button> */}
        </form>
        <div id="resumeSuccess">
            <div className="text-center">
                <svg className='img-fluid mb-2' xmlns="http://www.w3.org/2000/svg" width="113.625" height="113.133" viewBox="0 0 113.625 113.133">
                    <g id="Icon_feather-check-circle" data-name="Icon feather-check-circle" transform="translate(-1.49 -1.43)" stroke={ responseStatus == 'error' ? '#ff002a' : '#00ff2a' }>
                        <path id="Path_1341" data-name="Path 1341" d="M112.994,52.962v5.06A55,55,0,1,1,80.381,7.755" transform="translate(0 0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                        <path id="Path_1342" data-name="Path 1342" d="M85,6,30,61.052l-16.5-16.5" transform="translate(27.998 8.024)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                    </g>
                </svg>
                <p>{ responseMessage }</p>
                {/* <p>Resume Uploaded Successfully</p> */}
                <a href="/" className='mainBtn'>Go to Home</a>
            </div>
        </div>
    </Popupz>
}