import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import http from "../../httpService";
//import Popupz from '../../components/Popupz';

// const skillset = [
//     { label: 'PHP' },
//     { label: 'Javascript' },
//     { label: 'HTML' },
// ]

const skillset = [
    'PHP',
    'Javascript',
    'HTML',
]

var eduCount = 0, workCount = 0, skillCount = 0, linkCount = 0;



const WorkField = (props) => {
    const DeleteWork = (e) => {
        props.deleteWorkExperience(props.workExperienceIndex);
    }

    return (
        <div className="row" id={"work" + props.dataCount}>
            <div className="col-md-6">
                <div className="inpGroup">
                    <label htmlFor={"designation" + props.dataCount}>Designation*</label>
                    <input type="text" name="designation" value={props.designation} onChange={event => props.onChange(props.workExperienceIndex, event.target.name, event.target.value)} id={"designation" + props.dataCount} placeholder='Designation' required />
                </div>
            </div>
            <div className="col-md-6">
                <div className="inpGroup">
                    <label htmlFor={"company" + props.dataCount}>Company Name*</label>
                    <input type="text" name="company" value={props.company} onChange={event => props.onChange(props.workExperienceIndex, event.target.name, event.target.value)} id={"company" + props.dataCount} placeholder='Company Name' required />
                </div>
            </div>
            <div className="col-md-6">
                <div className="toggleBtnDiv">
                    <input type="checkbox" name="is_current_job" checked={props.is_current_job == 1} onClick={event => props.onChange(props.workExperienceIndex, event.target.name, event.target.value)} id={"toggle" + props.workExperienceIndex} />
                    <label htmlFor={"toggle" + props.workExperienceIndex} data-id={"work-to" + props.dataCount}><span>Current Job</span> <span className="toggleBtn"></span></label>
                </div>
            </div>
            <div className="col-md-6"></div>
            <div className="col-md-6">
                <div className="inpGroup dateGrp">
                    <label>From*</label>
                    <select className='year' name="from_year" value={props.from_year} onChange={event => props.onChange(props.workExperienceIndex, event.target.name, event.target.value)} id={"work-year-from" + props.dataCount} required>
                        <option value="2017">2017</option>
                        <option value="2018">2018</option>
                        <option value="2019">2019</option>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                    </select>
                    <select className='month' name="from_month" value={props.from_month} onChange={event => props.onChange(props.workExperienceIndex, event.target.name, event.target.value)} id={"work-month-from" + props.dataCount} required>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                </div>
            </div>
            <div className="col-md-6">
                <div id={"work-to" + props.dataCount} className={props.is_current_job ? 'inpGroup dateGrp wrkto disable' : 'inpGroup dateGrp wrkto'}>
                    <label>To*</label>
                    <select className="year" name="to_year" disabled={props.is_current_job} value={props.to_year} onChange={event => props.onChange(props.workExperienceIndex, event.target.name, event.target.value)} id={"work-year-to" + props.dataCount} required>
                        <option value="2017">2017</option>
                        <option value="2018">2018</option>
                        <option value="2019">2019</option>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                    </select>
                    <select className="month" name="to_month" disabled={props.is_current_job} value={props.to_month} onChange={event => props.onChange(props.workExperienceIndex, event.target.name, event.target.value)} id={"work-month-to" + props.dataCount} required>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                </div>
            </div>
            <div className="col-12 text-end">
                <button className="altBtn" type="button" onClick={DeleteWork} data_id={"work" + props.dataCount}><i className="fas fa-trash"></i></button>
            </div>
        </div>
    )
}

const EducationField = (props) => {
    const DeleteEdu = (e) => {
        // var eduDiv = e.target.getAttribute("data_id");
        // document.getElementById(eduDiv).remove();
        props.deleteEducationalQualification(props.educationalQualificationIndex);
    }
    return (
        <div className="row" id={"educationz" + props.dataCount}>
            <div className="col-12">
                <div className="inpGroup">
                    <label htmlFor={"school" + props.dataCount}>School/University*</label>
                    <input type="text" name="institution" value={props.institution} onChange={event => props.onChange(props.educationalQualificationIndex, event.target.name, event.target.value)} id={"school" + props.dataCount} placeholder='School/University' required />
                </div>
            </div>
            <div className="col-md-4">
                <div className="inpGroup">
                    <label htmlFor={"degree" + props.dataCount}>Degree*</label>
                    <input type="text" name="degree" value={props.degree} onChange={event => props.onChange(props.educationalQualificationIndex, event.target.name, event.target.value)} id={"degree" + props.dataCount} placeholder='Degree' required />
                </div>
            </div>
            <div className="col-md-4">
                <div className="inpGroup">
                    <label htmlFor={"branch" + props.dataCount}>Field of Study*</label>
                    <input type="text" name="study_field" value={props.study_field} onChange={event => props.onChange(props.educationalQualificationIndex, event.target.name, event.target.value)} id={"branch" + props.dataCount} placeholder='Field of Study' required />
                </div>
            </div>
            <div className="col-md-4">
                <div className="inpGroup">
                    <label htmlFor={"cgpa" + props.dataCount}>CGPA(optional)</label>
                    <input type="text" name="cgpa" value={props.cgpa} onChange={event => props.onChange(props.educationalQualificationIndex, event.target.name, event.target.value)} id={"cgpa" + props.dataCount} placeholder='CGPA' />
                </div>
            </div>
            <div className="col-md-6">
                <div className="inpGroup dateGrp">
                    <label>From</label>
                    <select className='year' name="from_year" value={props.from_year} onChange={event => props.onChange(props.educationalQualificationIndex, event.target.name, event.target.value)} id={"year-from" + props.dataCount} required>
                        <option value="2017">2017</option>
                        <option value="2018">2018</option>
                        <option value="2019">2019</option>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                    </select>
                    <select className='month' name="from_month" value={props.from_month} onChange={event => props.onChange(props.educationalQualificationIndex, event.target.name, event.target.value)} id={"month-from" + props.dataCount} required>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                </div>
            </div>
            <div className="col-md-6">
                <div className="inpGroup dateGrp">
                    <label>To</label>
                    <select className='year' name="to_year" value={props.to_year} onChange={event => props.onChange(props.educationalQualificationIndex, event.target.name, event.target.value)} id={"year-to" + props.dataCount} required>
                        <option value="2017">2017</option>
                        <option value="2018">2018</option>
                        <option value="2019">2019</option>
                        <option value="2020">2020</option>
                        <option value="2021">2021</option>
                        <option value="2022">2022</option>
                    </select>
                    <select className='month' name="to_month" value={props.to_month} onChange={event => props.onChange(props.educationalQualificationIndex, event.target.name, event.target.value)} id={"month-to" + props.dataCount} required>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                </div>
            </div>
            <div className="col-12 text-end">
                <button className="altBtn" type="button" onClick={DeleteEdu} data_id={"educationz" + props.dataCount}><i className="fas fa-trash"></i></button>
            </div>
        </div>
    )
}

const SkillField = (props) => {
    const DeleteSkills = (e) => {
        
        props.deleteSkill(props.skillIndex);
    }
    
    return (

        <div className="row skills" id={"skills" + props.dataCount}>
            <div className="col-md-5 col-lg-6">
                <div className="inpGroup">
                    <label htmlFor="skill-name">Skill Name</label>
                    <Autocomplete
                        value={props.name}
                        disablePortal
                        id={"skill-name-" + props.dataCount}
                        options={props.skillSet}
                        required
                        freeSolo
                        renderInput={(params) => <TextField
                            {...params}
                            onKeyUp={e => {
                                // if (e.code === 'enter' && e.target.value) {
                                props.onChange(props.skillIndex, 'name', e.target.value);
                                // }
                            }}
                        />}
                        onChange={(event, newValue) => {
                            
                            props.onChange(props.skillIndex, 'name', newValue);
                        }}
                    />
                </div>
            </div>
            <div className="col-lg-5">
                <div className="inpGroup">
                    <label htmlFor={"skill-set" + props.dataCount}>Skill Experience (in years)*</label>
                    <input type="number" name="experience" value={props.experience} id={"skill-set" + props.dataCount} placeholder='Skill Experience' required onChange={event => props.onChange(props.skillIndex, 'experience', event.target.value)} />
                </div>
            </div>
            <div className="col-lg-1 col-md-2 align-content-end d-grid">
                <div className="inpGroup">
                    <button className="altBtn w-100" type="button" onClick={DeleteSkills} data_id={"skills" + props.dataCount}><i className="fas fa-trash"></i></button>
                </div>
            </div>
        </div>
    )
}

const LinkField = (props) => {
    const DeleteLink = (e) => {
        var linkDiv = e.target.getAttribute("data_id");
        
        document.getElementById(linkDiv).remove();
    }
    return (

        <div className="row links" id={"links" + props.dataCount}>
            <div className="col-lg-4 col-md-5">
                <div className="inpGroup">
                    <label htmlFor={"link-label-" + props.dataCount}>Link Label*</label>
                    <input type="text" name={"link-label-" + props.dataCount} id={"skill-set" + props.dataCount} placeholder='Link Label' required onChange={event => props.onChange(props.AddLinkIndex, 'label', event.target.value)} value={props.label} />
                </div>
            </div>
            <div className="col-lg-7 col-md-5">
                <div className="inpGroup">
                    <label htmlFor={"link-" + props.dataCount}>Link*</label>
                    <input type="text" name={"link-" + props.dataCount} id={"link" + props.dataCount} placeholder='Link' required onChange={event => props.onChange(props.AddLinkIndex, 'link', event.target.value)} value={props.link} />
                </div>
            </div>
            <div className="col-lg-1 col-md-2 align-content-end d-grid">
                <div className="inpGroup">
                    <button className="altBtn w-100" type="button" onClick={DeleteLink} data_id={"links" + props.dataCount}><i className="fas fa-trash"></i></button>
                </div>
            </div>
        </div>
    )
}

class EditProfileCandidate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // application_id: "",
            first_name: undefined,
            last_name: undefined,
            avatar: undefined,
            // auth_token: "",
            skillSet: [],
            phone: undefined,
            work_phone: undefined,
            current_designation: undefined,
            bio: undefined,
            // skills: "",
            email: undefined,
            address: undefined,
            experience: '',
            skills: [],
            educationalQualifications: [],
            workExperiences: [],
            AddLink: [],
            showSuccess: false,
            errors: {
                first_name: undefined,
                last_name: undefined,
                bio: undefined,
                current_designation: undefined,
                phone: undefined,
                work_phone: undefined,
                email: undefined,
                address: undefined,
                experience: undefined,
            }
            // inputList: [],
            // inputList2: [],
            // inputList3: [],
            // inputList4: [],
            // ProfilePic: "",
            // AddSkill: "",
            // AddEducation: "",
            // AddWork: "",
            // AddLink: ""
        };
    }

    componentDidMount() {
        // let usertoken = localStorage.getItem('AuthData');
        // let userdata = JSON.parse(usertoken);
        // if (userdata) {
        //     this.getApplicationdetails(userdata);
        // }

        this.getEditProfileDetails();
        this.pagepermission();
        this.getSkillSet();
    }

    getSkillSet = async () => {
        const response = await http.get('api/getskills');

        const skillSet  = response.data.skill.map(skill => {return skill.name});
        
        this.setState({
            skillSet
        });
    }

    pagepermission = async () => {
        let authData = localStorage.getItem('AuthData');
        if (authData) {
            try {
                const response = await http.get('api/userrole');
                if (!response.data.is_job_seeker) {
                    window.location.href = '/my-account-employer';
                }
            } catch (exception) {

            }
        }
    }


    getEditProfileDetails = async () => {

        // let logouttoken = userdata.auth_token;
        // let config = {
        //     headers: { Authorization: 'Bearer ' + logouttoken }
        // };

        const response = await http.get('api/edit-profile');

        if (response.data.status && response.data.status === 'success') {
            this.setState({
                bio: response.data.bio ? response.data.bio : "",
                email: response.data.email,
                phone: response.data.phone ? response.data.phone : undefined,
                skills: response.data.skills,
                avatar: response.data.avatar,
                address: response.data.address ? response.data.address : "",
                last_name: response.data.last_name,
                first_name: response.data.first_name,
                work_phone: response.data.work_phone ? response.data.work_phone : undefined,
                current_designation: response.data.current_designation ? response.data.current_designation : "",
                educationalQualifications: response.data.educationalQualifications,
                workExperiences: response.data.workExperiences,
                AddLink: response.data.userlink,
                experience: response.data.experience,
                // application_id: userdata.id,
                // firstname: userdata.firstname,
                // lastname: userdata.lastname,
                // phone: userdata.phone,
                // // auth_token: logouttoken,
                // email: userdata.email
            });
        }
    }

    ProfilePic = () => {
        const choosedFile = document.getElementById("profilePicInput").files[0];
        this.setState({
            avatar: choosedFile
        });
        const reader = new FileReader();
        reader.addEventListener('load', function () {
            document.getElementById("profilePic").src = reader.result;
        });
        reader.readAsDataURL(choosedFile);
    }

    AddSkill = () => {
        this.setState({
            // skills: this.state.skills.concat(<SkillField dataCount={skillCount} key={skillCount} />),
            skills: this.state.skills.concat({}),
        })
        // skillCount++;
    }

    handleSkillChange = (skillIndex, attributeName, value) => {

        const skills = this.state.skills;

        if (skills.length > skillIndex) {

            skills[skillIndex][attributeName] = value;

            this.setState({
                skills
            });
        }
    }
    deleteSkill = (skillIndex) => {
        let skills = this.state.skills;

        if (skills.length > skillIndex) {

            skills.splice(skillIndex, 1);

            this.setState({
                skills
            });
        }
    }

    AddEducation = () => {
        this.setState({
            // educationalQualifications: this.state.educationalQualifications.concat(<EducationField dataCount={eduCount} key={eduCount} />),
            educationalQualifications: this.state.educationalQualifications.concat({}),
        })
        // eduCount++;
    }

    handleEducationalQualificationChange = (educationalQualificationIndex, attributeName, value) => {

        const educationalQualifications = this.state.educationalQualifications;

        if (educationalQualifications.length > educationalQualificationIndex) {

            educationalQualifications[educationalQualificationIndex][attributeName] = value;

            this.setState({
                educationalQualifications
            });
        }
    }

    deleteEducationalQualification = (educationalQualificationIndex) => {
        let educationalQualifications = this.state.educationalQualifications;

        if (educationalQualifications.length > educationalQualificationIndex) {

            educationalQualifications.splice(educationalQualificationIndex, 1);

            this.setState({
                educationalQualifications
            });
        }
    }

    AddWork = () => {
        this.setState({
            // workExperiences: this.state.workExperiences.concat(<WorkField dataCount={workCount} key={workCount} />)
            workExperiences: this.state.workExperiences.concat({})
        })
        // workCount++;
    }

    handleWorkExperienceChange = (workExperienceIndex, attributeName, value) => {

        const workExperiences = this.state.workExperiences;

        if (workExperiences.length > workExperienceIndex) {

            switch (attributeName) {

                case 'is_current_job':
                    workExperiences[workExperienceIndex][attributeName] = workExperiences[workExperienceIndex][attributeName] == 1 ? 0 : 1;
                    break;


                default:
                    workExperiences[workExperienceIndex][attributeName] = value;
                    break;
            }

            this.setState({
                workExperiences
            });
        }
    }

    deleteWorkExperience = (workExperienceIndex) => {
        let workExperiences = this.state.workExperiences;

        if (workExperiences.length > workExperienceIndex) {

            workExperiences.splice(workExperienceIndex, 1);

            this.setState({
                workExperiences
            });
        }
    }

    AddLink = () => {
        this.setState({
            //inputList4: this.state.inputList4.concat(<LinkField dataCount={linkCount} key={linkCount} />),
            AddLink: this.state.AddLink.concat({}),
        })
    }

    handleAddLinkChange = (AddLinkIndex, attributeName, value) => {

        const AddLink = this.state.AddLink;

        if (AddLink.length > AddLinkIndex) {

            AddLink[AddLinkIndex][attributeName] = value;
            this.setState({
                AddLink
            });
        }
    }
    deleteAddLink = (AddLinkIndex) => {
        let AddLink = this.state.AddLink;

        if (AddLink.length > AddLinkIndex) {

            AddLink.splice(AddLinkIndex, 1);

            this.setState({
                AddLink
            });
        }
    }

    handleInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleOnChange = (value, data, event, formattedValue) => {
        
        this.setState({
            phone: event.target.value,
        });
    }

    handleOnChangework = (value, data, event, formattedValue) => {
        this.setState({
            work_phone: event.target.value,
        });
    }

    saveProfile = async () => {
        const formData = new FormData();
        formData.append('jobs_type', '2');
        formData.append('first_name', this.state.first_name);
        formData.append('last_name', this.state.last_name);
        formData.append('phone', this.state.phone);
        formData.append('email', this.state.email);
        formData.append('avatar', this.state.avatar);
        formData.append('bio', this.state.bio);
        formData.append('current_designation', this.state.current_designation);
        formData.append('work_phone', this.state.work_phone);
        formData.append('experience', this.state.experience);
        formData.append('address', this.state.address);
        formData.append('skills', JSON.stringify(this.state.skills));
        formData.append('educationalQualifications', JSON.stringify(this.state.educationalQualifications));
        formData.append('workExperiences', JSON.stringify(this.state.workExperiences));
        formData.append('userlink', JSON.stringify(this.state.AddLink));

        // this.state.skills.forEach(item => {
        //     formData.append(`skills[]`, JSON.stringify(item));
        // });
        try {
            let response = await http.post("api/update-profile", formData);

            if (response.data.status && response.data.status == 'success') {
                this.setState({
                    showSuccess: true,
                });
                // alert (response.data.message);
                // window.location.href = '/my-account';
            }
        } catch (exception) {

            if (exception.response && exception.response.data) {
                this.setState({
                    errors: exception.response.data
                })
            }
        }

    }

    toggleDivFunc = (e) => {
        var k = e.target.getAttribute("data-id");
        document.getElementById(k).classList.toggle("active");
        e.target.classList.toggle("active");
    }

    render() {

        const Popupz = (props) => {
            const closePopup = () => {
                document.getElementById(props.id).classList.remove("active");
                if (props.onClose) {
                    props.onClose();
                }
            }
          return (
            <>
                <div className={"bgOverlay " + props.className } id={props.id}>
                    <div className="popupCard">
                        <button className="closeBtn" onClick={closePopup}><i className="bi bi-x-lg"></i></button>
                        <h2 className="popupHead">{props.title}</h2>
                        <div className="maxHeight">
                        {props.children}
                        </div>
                    </div>
                </div>
            </>
          )
        }
        return (
            <>
                <main>
                    <section className="sectionPad editProfile" id="topInnerPage">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <div className="mainTitle">
                                        <h2>Edit Profile</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <form className="zForm white">
                                        <div className="glassCard userIntro mb-3">
                                            <div className="div85">
                                                <input type="file" name="profilePicInput" id="profilePicInput"
                                                    accept="image/png, image/gif, image/jpeg" onChange={this.ProfilePic} />
                                                <label className="imgSq">
                                                    <img src={this.state.avatar ? this.state.avatar : "/img/user.svg"}
                                                        alt="img" id="profilePic" />
                                                    <label htmlFor="profilePicInput" id="edit"><i
                                                        className="fas fa-pencil-alt"></i></label>
                                                </label>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="inpGroup">
                                                        <label htmlFor="fname">Firstname*</label>
                                                        <input type="text" name="first_name" id='fname' placeholder='John' required value={this.state.first_name} onChange={this.handleInputChange} />
                                                        <div className="text-danger">{this.state.errors.first_name}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="inpGroup">
                                                        <label htmlFor="lname">Lastname*</label>
                                                        <input type="text" name="last_name" id='lname' placeholder='Doe' required value={this.state.last_name} onChange={this.handleInputChange} />
                                                        <div className="text-danger">{this.state.errors.last_name}</div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="inpGroup">
                                                        <label htmlFor="desig">Current Designation*</label>
                                                        <input type="text" name="current_designation" id='current_designation' placeholder='Designation' required value={this.state.current_designation} onChange={this.handleInputChange} />
                                                        <div className="text-danger">{this.state.errors.current_designation}</div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="inpGroup">
                                                        <label htmlFor="bio">Bio*</label>
                                                        <textarea name="bio" id="bio" rows="10" required value={this.state.bio} onChange={this.handleInputChange}></textarea>
                                                        <div className="text-danger">{this.state.errors.bio}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="glassCard userIntro mb-3 z1">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="d-flex justify-content-between mb-2"><h4>Skills</h4> <button type="button" className="altBtn toggleDivBtn" data-id="skillDivRow" onClick={this.toggleDivFunc}><i className="fas fa-angle-down"></i></button></div>
                                                </div>
                                            </div>
                                            <div className="row toggleDiv" id="skillDivRow">
                                                <div className="col-12" id="skills">
                                                    {this.state.skills.map((skill, skillIndex) => <>{this.state.skillSet.length }<SkillField {...skill} skillIndex={skillIndex} key={skillIndex} onChange={this.handleSkillChange} deleteSkill={this.deleteSkill} skillSet={this.state.skillSet} /></>)}
                                                </div>
                                                <div className="col-12 text-end">
                                                    <button className="mainBtn" type='button' onClick={this.AddSkill}>Add Skills</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="glassCard userIntro mb-3">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="d-flex justify-content-between mb-2"><h4>Educational Qualifications</h4> <button type="button" className="altBtn toggleDivBtn" data-id="eduDivRow" onClick={this.toggleDivFunc}><i className="fas fa-angle-down"></i></button></div>
                                                </div>
                                            </div>
                                            <div className="row toggleDiv" id="eduDivRow">
                                                <div className="col-12" id="education">
                                                    {this.state.educationalQualifications.map((educationalQualification, educationalQualificationIndex) => <EducationField {...educationalQualification} educationalQualificationIndex={educationalQualificationIndex} key={educationalQualificationIndex} onChange={this.handleEducationalQualificationChange} deleteEducationalQualification={this.deleteEducationalQualification} />)}
                                                </div>
                                                <div className="col-12 text-end">
                                                    <button className="mainBtn" type='button' onClick={this.AddEducation}>Add
                                                        Education</button>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="glassCard userIntro mb-3">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="d-flex justify-content-between mb-2"><h4>Work Experience</h4> <button type="button" className="active altBtn toggleDivBtn" data-id="workDivRow" onClick={this.toggleDivFunc}><i className="fas fa-angle-down"></i></button></div>
                                                </div>
                                            </div>
                                            <div className="row toggleDiv active" id="workDivRow">
                                                <div className="col-12">
                                                    <div className="inpGroup">
                                                        <label htmlFor="experience">Total Years of Experience*</label>
                                                        <input type="number" name="experience" id='experience' placeholder='' required value={this.state.experience} onChange={this.handleInputChange} min="0"/>
                                                        <div className="text-danger">{this.state.errors.experience}</div>
                                                    </div>
                                                </div>
                                                <div className="col-12" id="workexperience">
                                                    {this.state.workExperiences.map((workExperience, workExperienceIndex) => <WorkField {...workExperience} workExperienceIndex={workExperienceIndex} key={workExperienceIndex} onChange={this.handleWorkExperienceChange} deleteWorkExperience={this.deleteWorkExperience} />)}
                                                </div>
                                                <div className="col-12 text-end">
                                                    <button className="mainBtn" type='button' onClick={this.AddWork}>Add Work Experience</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="glassCard userIntro">
                                            <div className="row">
                                                <div className="col-12 mb-4">
                                                    <h4>Contact Info</h4>
                                                </div>
                                                <div className="col-12" id="workexperience">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="inpGroup">
                                                                <label htmlFor="phone">Phone(Mobile)*</label>
                                                                <PhoneInput placeholder="+91 XXXXX-XXXXX" name="phone" required value={this.state.phone} onChange={this.handleOnChange} country={'in'} />
                                                                <div className="text-danger">{this.state.errors.phone}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="inpGroup">
                                                                <label htmlFor="phone">Phone(Work)</label>
                                                                <PhoneInput placeholder="+91 XXXXX-XXXXX" name="work_phone" value={this.state.work_phone} onChange={this.handleOnChangework} country={'in'} />
                                                                <div className="text-danger">{this.state.errors.work_phone}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="inpGroup">
                                                                <label htmlFor="email">Email*</label>
                                                                <input type="text" name="email" id='email' placeholder='johndoe@email.com' required value={this.state.email} onChange={this.handleInputChange} />
                                                                <div className="text-danger">{this.state.errors.email}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="inpGroup">
                                                                <label htmlFor="addr">Address*</label>
                                                                <textarea name="address" id="addr" rows="10" placeholder="Address" required onChange={this.handleInputChange} value={this.state.address}></textarea>
                                                                <div className="text-danger">{this.state.errors.address}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.AddLink.map((AddLink, AddLinkIndex) => <LinkField {...AddLink} AddLinkIndex={AddLinkIndex} key={AddLinkIndex} onChange={this.handleAddLinkChange} deleteWorkExperience={this.deleteAddLink} />)}
                                            <div className="row">
                                                <div className="col-12 text-end">
                                                    <button className="mainBtn mb-2" type='button' onClick={this.AddLink}>Add Link</button>
                                                </div>
                                                <div className="col-12 text-end">
                                                    <a href="/my-account" className="altBtn">Cancel</a> &nbsp; <button className="mainBtn" onClick={this.saveProfile} type='button'>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Popupz title="" id="uploadResume" className={this.state.showSuccess ? "active" : ''}>
                    <div id="resumeSuccess" className="active">
                        <div className="text-center">
                            <svg className='img-fluid mb-2' xmlns="http://www.w3.org/2000/svg" width="113.625" height="113.133" viewBox="0 0 113.625 113.133">
                                <g id="Icon_feather-check-circle" data-name="Icon feather-check-circle" transform="translate(-1.49 -1.43)" stroke='#00ff2a'>
                                    <path id="Path_1341" data-name="Path 1341" d="M112.994,52.962v5.06A55,55,0,1,1,80.381,7.755" transform="translate(0 0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                    <path id="Path_1342" data-name="Path 1342" d="M85,6,30,61.052l-16.5-16.5" transform="translate(27.998 8.024)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                </g>
                            </svg>
                            <p>Profile updated successfully</p>
                            <a href="/my-account" className='mainBtn'>Go To Profile</a>
                        </div>
                    </div>
                </Popupz>
            </>
        );
    }
}

export default EditProfileCandidate
