import React, { useState, useEffect } from 'react';
import http from '../httpService';



const BlogList = () => {
    let [blogDetails, setBlogDetails] = useState(null);
    let month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    useEffect(() => {
        http.get('api/getblog')
            .then((response) => {
                setBlogDetails(response.data.blogs);
            });
    }, [])

    return (
        <div className="container">
            <div className="row justify-content-center">
                {blogDetails ? blogDetails.map((details) => {
                    let tag_list = JSON.parse(details.blog_tag);
                    let full_date_split = new Date(details.blog_date);
                    let correctmonth = "";
                    month.map((month, index) => {
                        if(full_date_split.getMonth() === index){
                            correctmonth = month;
                        }
                    })
                    let full_date = full_date_split.getDate() + " " + correctmonth + " " + full_date_split.getFullYear();

                    return (
                        <div className="col-lg-4 col-md-12">
                            <a href={"/blog/" + details.blog_slug} className="blogCard">
                                <div className="imgSection">
                                    <img src={details.blog_image} alt="blog" />
                                </div>
                                <div className="textSection">
                                    <h3>{details.blog_title}</h3>
                                    <p><div dangerouslySetInnerHTML={{ __html: details.blog_body }}></div></p>
                                    <ul className="blogTags">
                                        {tag_list ? tag_list.map((tag) => <li>{tag}</li>) : null}
                                    </ul>
                                    <div className="blogFooter">
                                        <div className="author">
                                            <img src={details.blog_author_image} alt="img" />
                                            <div className="name">
                                                <p>by</p>
                                                <h4>{details.blog_author}</h4>
                                            </div>
                                        </div>
                                        <span className="dateBlog">
                                            <i className="far fa-calendar"></i><span>{full_date}</span>
                                        </span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    )
                }
                ) : null}
            </div>
        </div>
    )
}

export default BlogList