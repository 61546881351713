import React, { Component } from 'react';
import http from '../../httpService';
import AddResume from '../Resume/Add';
import Popupz from '../../components/Popupz';

function SuccessPopup(props) {
    return (
        <div>
            <Popupz title="Vacancy Applied" id="uploadResume" className={props.show ? 'active' : ''} onClose={props.onClose}>
                <div className="text-center">
                    {props.icon}
                    <p>{props.message}</p>
                    <a href="/" className='mainBtn'>Go to Home</a>
                </div>
            </Popupz>
        </div>
    )
}

export default class Index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fetchingData: false,
            vacancies: [],
            userDetails: [],
            showAddResume: false,
            showPopup: false,
            showmessage: '',
            vacancyid: '',
            showIcon: <svg className='img-fluid mb-2' xmlns="http://www.w3.org/2000/svg" width="113.625" height="113.133" viewBox="0 0 113.625 113.133">
                <circle class="st0" cx="56.3" cy="56.6" r="55" style={{ 'fill': 'none', 'stroke': '#FF0000', 'stroke-width': '3', 'stroke-linecap': 'round', 'stroke-linejoin': 'round' }} />
                <line class="st0" x1="37.4" y1="37.6" x2="75.2" y2="75.5" style={{ 'fill': 'none', 'stroke': '#FF0000', 'stroke-width': '3', 'stroke-linecap': 'round', 'stroke-linejoin': 'round' }} />
                <line class="st0" x1="37.4" y1="75.5" x2="75.2" y2="37.6" style={{ 'fill': 'none', 'stroke': '#FF0000', 'stroke-width': '3', 'stroke-linecap': 'round', 'stroke-linejoin': 'round' }} />
            </svg>
        }
    }

    componentDidMount() {
        this.getVacancies();
    }

    getVacancies = async () => {
        const response = await http.get("api/vacancies");

        if (response.data && response.data.vacancies) {
            this.setState({
                vacancies: response.data.vacancies,
            })
        }
        http.get('api/edit-profile')
            .then((response) => {
                this.setState({
                    userDetails: response.data,
                })
            });
    }

    AppliedVacancy = async (event) => {
        let authData = localStorage.getItem('AuthData');
        let vacancyid = event.target.dataset.id;
        if (authData) {
            if (this.state.userDetails) {
                const formData = new FormData();
                formData.append('vacancy_id', vacancyid);
                try {
                    let response = await http.post("api/vacancy-insert", formData);

                    if (response.data.status) {
                        this.setState({
                            showIcon: <svg className='img-fluid mb-2' xmlns="http://www.w3.org/2000/svg" width="113.625" height="113.133" viewBox="0 0 113.625 113.133">
                                <g id="Icon_feather-check-circle" data-name="Icon feather-check-circle" transform="translate(-1.49 -1.43)" stroke='#00ff2a'>
                                    <path id="Path_1341" data-name="Path 1341" d="M112.994,52.962v5.06A55,55,0,1,1,80.381,7.755" transform="translate(0 0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                    <path id="Path_1342" data-name="Path 1342" d="M85,6,30,61.052l-16.5-16.5" transform="translate(27.998 8.024)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                </g>
                            </svg>
                        })
                    }
                    this.setState({
                        showmessage: response.data.message,
                        showPopup: true,
                        vacancyid: vacancyid
                    })
                } catch (exception) {
                    if (exception.response) {
                        console.log(exception.response);
                    }
                }
            }

        } else {
            this.setState({
                showAddResume: true,
                vacancyid: vacancyid
            })
        }
    }

    render() {

        return (
            <main>
                <section id="topInnerPage" className="sectionPad career">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="mainTitle">
                                    <h2>Current Openings</h2>
                                </div>
                            </div>
                            {/* <div className="col-12">
                            <div className="glassCard">
                                <form action="" className="zForm white">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="inpGroup">
                                                <label htmlFor="search">Keyword</label>
                                                <input type="search" id="search" placeholder="Search" />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="inpGroup">
                                                <label htmlFor="skills">Skills</label>
                                                <input type="text" id='skill' placeholder='Skills' />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="inpGroup">
                                                <label htmlFor="loca">Location</label>
                                                <input type="text" id='loca' placeholder='Location' />
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="inpGroup">
                                                <label htmlFor="expe">Experience</label>
                                                <select name="expe" id="expe">
                                                    <option value="" disabled selected hidden>Select Experience</option>
                                                    <option value="0-1">0 - 1 yrs</option>
                                                    <option value="1-2">1 - 2 yrs</option>
                                                    <option value="2-3">2 - 3 yrs</option>
                                                    <option value="3-4">3 - 4 yrs</option>
                                                    <option value="4-5">4 - 5 yrs</option>
                                                    <option value="5+">Above 5 yrs</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-12 text-end">
                                            <button type="submit" className="mainBtn">Search <i className="bi bi-search"></i></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div> */}
                        </div>
                        <div className="row">

                            {
                                this.state.vacancies &&
                                this.state.vacancies.map((vacancy) => {
                                    return (
                                        <div className="col-lg-6">
                                            <div className="jobCard">
                                                <a href={"/careers/" + vacancy.slug} style={{ 'textDecoration': 'none', 'color': 'inherit' }}>
                                                    <div className="jobHead">
                                                        <h3>{vacancy.title}</h3>
                                                        <span className="jobId">ID: {vacancy.code}</span>
                                                    </div>
                                                    <p className="place">{vacancy.location}</p>
                                                    <p className="remote">{vacancy.short_description}</p>
                                                </a>
                                                <div className="bottom">
                                                    <p className="price"><i className="bi bi-coin"></i> {vacancy.pay_range}</p>
                                                    <button type='button' className="mainBtn" data-id={vacancy.id} onClick={this.AppliedVacancy}>Apply Now</button>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }


                            {/* <div className="col-lg-6">
                                <div className="jobCard">
                                    <div className="jobHead">
                                    <h3>FULL-STACK ENGINEER (RUBY ON RAILS)</h3>
                                    <span className="jobId">ID: CH10008</span>
                                    </div>
                                    <p className="place">Ernakulam, Kerala, India</p>
                                    <p className="remote">This job provide remote work option</p>
                                    <div className="bottom">
                                        <p className="price"><i className="bi bi-coin"></i>11 - 22L p.a</p>
                                        <a href="/career-details/fullstack-ruby" className="mainBtn">Apply Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="jobCard">
                                    <div className="jobHead">
                                    <h3>DEV-OPS ENGINEER</h3>
                                    <span className="jobId">ID: CH10009</span>
                                    </div>
                                    <p className="place">Ernakulam, Kerala, India</p>
                                    <p className="remote">This job provide remote work option</p>
                                    <div className="bottom">
                                        <p className="price"><i className="bi bi-coin"></i>Competitive</p>
                                        <a href="/career-details/devops-engineer" className="mainBtn">Apply Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="jobCard">
                                    <div className="jobHead">
                                    <h3>FULL-STACK ENGINEER (JAVASCRIPT)</h3>
                                    <span className="jobId">ID: CH10010</span>
                                    </div>
                                    <p className="place">Ernakulam, Kerala, India</p>
                                    <p className="remote">This job provide remote work option</p>
                                    <div className="bottom">
                                        <p className="price"><i className="bi bi-coin"></i>9 - 24L p.a</p>
                                        <a href="/career-details/fullstack-javascript" className="mainBtn">Apply Now</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="jobCard">
                                    <div className="jobHead">
                                    <h3>JR.BUSINESS ANALYST</h3>
                                    </div>
                                    <p className="place">Ernakulam, Kerala, India</p>
                                    <p className="remote">This job provide remote work option</p>
                                    <div className="bottom">
                                        <p className="price"></p>
                                        <a href="/career-details/junior-analyst" className="mainBtn">Apply Now</a>
                                    </div>
                                </div>
                            </div> */}
                        </div>

                        <AddResume
                            show={this.state.showAddResume}
                            vacancyId={this.state.vacancyid}
                            onClose={() => this.setState({ showAddResume: false })}
                        />

                        <SuccessPopup
                            icon={this.state.showIcon}
                            message={this.state.showmessage}
                            show={this.state.showPopup}
                            vacancyId={this.state.vacancyid}
                            onClose={() => this.setState({ showPopup: false })}
                        />
                    </div>
                </section>
            </main>
        );
    }
};
