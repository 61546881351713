import React, { useState, useEffect, useRef } from 'react';
import http from '../../httpService';
import { useParams } from "react-router-dom";

const Popupz = (props) => {
    return (
        <>
            <div className={"bgOverlay " + props.className} id={props.id}>
                <div className="popupCard">
                    <button className="closeBtn" onClick={() => props.closePopup()}><i className="bi bi-x-lg"></i></button>
                    <h2 className="popupHead">{props.title}</h2>
                    <div className="maxHeight">
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    )
}


const IdeaPitch = () => {
    const [attachmentKey, setAttachmentKey] = useState(1);
    let [attachment, setAttachment] = useState('');
    let [AttachmentDownload, SetAttachmentDownload] = useState('');
    let { id } = useParams();
    let userskills = [];
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [showPopup, setShowPopup] = useState("");
    const [showTitle, setShowTitle] = useState("");
    const [message, setMessage] = useState(null);

    const [error, setError] = useState({
        name: null,
        number: null,
        attachment: null,
        email: null,
        phone: null
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        getPostData();
    }, []);

    const handleFileChange = (e) => {
        setAttachment(e.target.files[0]);
    };

    const getPostData = async () => {
        const formData = new FormData();
        formData.append('post_id', id);
        try {
            let response = await http.post("api/getpost", formData);
            if (response.data.post) {
                if (response.data.post.attachment) {
                    let attachment = JSON.parse(response.data.post.attachment);
                    let attachmentpath = response.data.url + attachment[0].download_link;
                    SetAttachmentDownload(<a href={attachmentpath} target="_blank" className='mainBtn'>Download</a>);
                }
                let skilldata = JSON.parse(response.data.post.skills);
                skilldata.map(item => userskills.push(item));
            }
        } catch (exception) {
            if (exception.response && exception.response.data && exception.response.data.errors) {
                console.log(exception.response.data.errors);
            }
        }
    }

    const Save = async () => {

        const formData = new FormData();
        formData.append("name", name);
        formData.append("number", number);
        formData.append("attachment", attachment);
        formData.append("email", email);
        formData.append("phone", phone);

        try {
            let response = await http.post("api/idea-pitch", formData);
            if (response && response.data) {
                setShowTitle("Success");
                setMessage(response.data.message);
                setShowPopup("active");
                setName("");
                setNumber("");
                setEmail("");
                setPhone("");
                setError("");
                const fileInput = document.getElementById('fileresult');
                if (fileInput) {
                    fileInput.innerHTML = "<span>&nbsp; Choose File</span>";
                }
            }
        } catch (exception) {
            if (exception.response && exception.response.data && exception.response.data.errors) {
                setError(exception.response.data.errors);
                // setSavingResume(false);
            }
        }
    }

    return (
        <>
            <main>
                <section id="heroSection" className='ideaPitchHero'>
                    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src="/img/pitch-idea/idea-banner-1.jpg" className="d-block w-100" alt="idea banner" />
                                <div className="carouselText">
                                    <div className="container"><h1>Do you have a groundbreaking idea?</h1></div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <img src="/img/pitch-idea/idea-banner-2.jpg" className="d-block w-100" alt="idea banner" />
                                <div className="carouselText">
                                    <div className="container"><h1>Are you meet with a funding problem?</h1></div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <img src="/img/pitch-idea/idea-banner-3.jpg" className="d-block w-100" alt="idea banner" />
                                <div className="carouselText">
                                    <div className="container"><h1>Don’t worry we’ll help you launch your Idea.</h1></div>
                                </div>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <i className="fas fa-chevron-circle-left"></i>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <i className="fas fa-chevron-circle-right"></i>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <a href="#topTalents" class="box">
                        <span></span>
                        <span></span>
                        <span></span>
                    </a>
                </section>
                <section id="qsummary" className='sectionPad '>
                    <div className="container">
                        <div className="row justify-content-center text-center">
                            <div className="col-12">
                                <h2>Hatch your Disruptive Idea to Reality</h2>
                            </div>
                            <div className="col-md-10 col-lg-8 col-xl-6">
                                <p>ClockHash Tech calls for Innovative Technology Ideas with Social Impact for Funding from Prospective Innovators/Creative Minds in India</p>
                                <a href="#ideaForm" className="mainBtn">Submit your Ideas</a>
                            </div>
                        </div>
                    </div>
                </section>
                <section id='about-program' className="bg-black sectionPad graText">
                    <div className="container">
                        <div className="row align-items-center flex-column-reverse flex-lg-row">
                            <div className="col-lg-6">
                                <div className="mainTitle left">
                                    <h2>What is this all about?</h2>
                                </div>
                                <p>Do you have a groundbreaking idea which you want to launch but missing real industrial technical expertise, market knowledge and funding? Our Innovator Mentoring and SupportTeam at ClockHash is willing to invest in your idea by providing what you lack or by addressing the gaps to take the idea to reality.</p>
                            </div>
                            <div className="col-lg-6">
                                <img src="/img/pitch-idea/explining.svg" alt="explaining" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </section>
                <section id="more-about" className='sectionPad graText'>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="mainTitle">
                                    <h2>More about us</h2>
                                </div>
                            </div>
                            <div className="col-lg-8-col-md-10 text-center">
                                <p className="bigText">ClockHash Technologies is excited to announce an open call for innovative technology ideas that have the potential to create a positive societal impact. We firmly believe in the power of technology to shape a better future, and we are dedicated to supporting ground-breaking projects primarily but not limited to, in the tech domains of AI, Embedded Systems, IoT, Web development, Mobile Technologies, Cybersecurity, DevOps, Cloud Computing Technologies etc.</p>
                                <p className="bigText">At ClockHash Technologies, we strive to foster a culture of innovation and collaboration. We recognize that transformative ideas often arise from creative minds outside our organization, and we are eager to partner with individuals and teams who share our vision of leveraging technology for social good. We are committed to providing not only financial support but also guidance, expertise, and a platform to amplify the impact of exceptional projects.</p>
                                <a href="" className="mainBtn">Read the Full Document</a>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="eligibility" className="sectionPad bg-black graText">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-6">
                                <div className="mainTitle left">
                                    <h2>Eligibility Criteria</h2>
                                </div>
                                <p>We embrace diversity and encourage applicants from the following backgrounds- individuals, teams, and potential startups that have not registered yet. Projects submitted should fall within but not limited to the disruptive realms of AI, Embedded Systems, IoT, Web Development and Mobile Technologies, Cybersecurity, DevOps, or Cloud Computing Technologies and must have a clear focus on generating positive social outcomes.  </p>
                            </div>
                            <div className="col-lg-6 text-center text-lg-end">
                                <img src="/img/pitch-idea/criteria.svg" alt="Checking Sheet of paper" className="img-fluid" />
                            </div>
                        </div>
                        <div className="row borderWrapper">
                            <div className="col-md-4 col-sm-6 pt-2 pb-2"><p className="mb-0">Please note that this is only open to those in India or globally? who haven’t received other grants/seed funding yet.</p></div>
                            <div className="col-md-4 col-sm-6 pt-2 pb-2"><p className="mb-0">Your idea should be a commercially viable one in the fields of Environment, Waste Management, Agriculture, Transportation, Climate Changes, Energy management, E commerce or any other that is socially impactful.</p></div>
                            <div className="col-md-4 col-sm-6 pt-2 pb-2"><p className="mb-0">It should be in modular design that can progress in phases – Meaning, the MVP could come out within a span of 6months.</p></div>
                            <div className="col-md-4 col-sm-6 pt-2 pb-2"><p className="mb-0">The envisaged, scalable product should be able to handle large volume of customers/data/traffic</p></div>
                            <div className="col-md-4 col-sm-6 pt-2 pb-2"><p className="mb-0">Ideas can be submitted as an Individual/Team with maximum Team size should not exceeding 4</p></div>
                            <div className="col-md-4 col-sm-6 pt-2 pb-2"><p className="mb-0">Industry experience of individual or team members should not exceed 3 years (i.e it should be within 0-3 years)</p></div>
                        </div>
                    </div>
                </section>

                <section id="howToHire" className="sectionPad graText guideline">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="mainTitle left">
                                    <h2>Submission Guidelines</h2>
                                </div>
                                <p>To apply for funding, please submit a detailed proposal that highlights the key aspects of your technology idea. Your proposal should include the following:</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="glassCard" data-num="1">
                                    <h3>Project Overview</h3>
                                    <p>Provide an executive summary that concisely describes your idea, its objectives, and the expected societal impact. Clearly articulate how your project aligns with ClockHash Technologies' insights on innovation (detailed in the introduction and foreword section) and the specific technology field it falls under.</p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="glassCard" data-num="2">
                                    <h3>Technical Details</h3>
                                    <p>Describe the technical aspects of your project, emphasizing the novelty, innovation, and potential scalability. Outline the core features, methodologies, algorithms, or frameworks that make your idea unique and cutting-edge. You could include proper technical supporting documents like videos, pictures, infographics, weblinks, press or magazine news etc.</p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="glassCard" data-num="3">
                                    <h3>Social Impact</h3>
                                    <p>Explain how your project addresses a significant social challenge or contributes to solving a pressing problem. Highlight the potential benefits it offers to individuals, communities, or society at large. Clearly articulate the positive outcomes you aim to achieve.</p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="glassCard" data-num="4">
                                    <h3>Implementation Plan</h3>
                                    <p>Present a comprehensive plan for implementing your project, including the necessary resources, milestones, and timelines. Demonstrate the feasibility and practicality of your idea, considering any potential challenges or risks that may arise during implementation.</p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="glassCard" data-num="5">
                                    <h3>Team and Expertise</h3>
                                    <p>Introduce the key members of your team, emphasizing their qualifications, relevant experience, and expertise. Highlight how your team is well-equipped to deliver on the proposed project.</p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="glassCard" data-num="6">
                                    <h3>Budget Estimation</h3>
                                    <p>Provide a detailed budget breakdown, outlining how the requested funding will be allocated. Ensure transparency and cost-effectiveness in your budget plan.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="evaluation-criteria" className="sectionPad graText">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="mainTitle left">
                                    <h2>Evaluation Criteria</h2>
                                </div>
                                <p>All proposals will be evaluated based on the following criteria:</p>
                            </div>
                        </div>
                        <div className="row topLine">
                            <div className="col-lg-3">
                                <h4>Innovation and Technical Excellence</h4>
                                <p>The degree of novelty, disruptiveness, technical rigor, and potential for advancement in the chosen technology field.</p>
                            </div>
                            <div className="col-lg-3">
                                <h4>Social Impact</h4>
                                <p>The extent to which the project addresses a pressing social challenge and generates positive outcomes for individuals or communities.</p>
                            </div>
                            <div className="col-lg-3">
                                <h4>Feasibility and Scalability</h4>
                                <p>The practicality of implementation, requirements of resources, & the potential for the idea to be scaled or replicated in different contexts.</p>
                            </div>
                            <div className="col-lg-3">
                                <h4>Team and Expertise</h4>
                                <p>The practicality of implementation, requirements of resources, & the potential for the idea to be scaled or replicated in different contexts.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12"><p className='text-center'>Submissions will be evaluated by ClockHash Technical Committee and the Shortlisted entries will be invited for further process</p></div>
                            <div className="col-lg-3"><p><strong>1.</strong>Idea Pitch presentation</p></div>
                            <div className="col-lg-3"><p><strong>2.</strong>Technical Evaluation Meeting of Team members</p></div>
                            <div className="col-lg-3"><p><strong>3.</strong>Business Model discussion with project roadmap</p></div>
                            <div className="col-lg-3"><p><strong>4.</strong>Idea onboarding/Selection</p></div>
                        </div>
                    </div>
                </section>
                <section id="ideaForm" className="sectionPad bg-black graText">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="mainTitle left">
                                    <h2>Pitch your ideas here.</h2>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <h4 className="fw-bold">Send you Proposal</h4>
                                <form className='zForm white'>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="inpGroup">
                                                <label htmlFor="fname">Name</label>
                                                <input type="text" name="fname" id='fname' placeholder='John Doe' onChange={(e) => setName(e.target.value)} value={name} />
                                                <div className="text-danger">{error.name ? error.name : ""}</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="inpGroup">
                                                <label htmlFor="tmember">No of Team members</label>
                                                <select name="tmember" id="tmember" onChange={(e) => setNumber(e.target.value)} value={number}>
                                                    <option value="" selected hidden disabled>Number</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                    <option value="4">Four</option>
                                                </select>
                                                <div className="text-danger">{error.number ? error.number : ""}</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="inpGroup">
                                                <label htmlFor="fname">Email</label>
                                                <input type="email" name="fname" id='fname' placeholder='John@gmail.com' onChange={(e) => setEmail(e.target.value)} value={email} />
                                                <div className="text-danger">{error.email ? error.email : ""}</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="inpGroup">
                                                <label htmlFor="fname">Phone No</label>
                                                <input type="number" name="fname" onChange={(e) => setPhone(e.target.value)} value={phone} placeholder="99999 99999" />
                                                <div className="text-danger">{error.phone ? error.phone : ""}</div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="inpGroup">
                                                <label htmlFor="salary">Upload Proposal</label>
                                                <div class="fileInpWrapper">
                                                    <input type="file" name="fileResume" id="fileResume" accept=".pdf,.docx" class="fileinp inputfile inputfile-1" data-multiple-caption="{count} files selected" onChange={handleFileChange} />
                                                    <label class="file-inp-l" for="fileResume"><span id="fileresult">&nbsp; Choose File</span><i className="bi bi-cloud-arrow-up"></i></label>
                                                    <div className="text-danger">{error.attachment ? error.attachment : ""}</div>
                                                </div>
                                            </div>
                                            <small className='mb-3 d-block'>Proposal should contain topics including <strong>Project Overview, Technical Details, Social Impact, Implementation Plan, Team and Expertise, Budget Estimation.</strong> To know more about these topics view <a href="" className='text-white'>Submission Guidelines.</a></small>
                                            <button onClick={Save} type="button" className='mainBtn'>Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="col-lg-4">
                                <h4 className="fw-bold">Contact us</h4>
                                <h5>ClockHash Technologies LLP</h5>
                                <div className="iconDiv">
                                    <div className="icon"><i className="fas fa-map-marker-alt"></i></div>
                                    <div className="textSection">
                                        <p>5 th Floor, Trans Asia Corporate Park, Cochin, India-682037</p>
                                    </div>
                                </div>
                                <div className="iconDiv">
                                    <div className="icon"><i className="fas fa-envelope"></i></div>
                                    <div className="textSection">
                                        <a href="mailto:praveen.cv@clockhash.com">praveen.cv@clockhash.com</a><br />
                                        <a href="mailto:hatchyouridea@clockhash.com">hatchyouridea@clockhash.com</a>
                                    </div>
                                </div>
                                <div className="iconDiv">
                                    <div className="icon"><i className="fas fa-phone-alt"></i></div>
                                    <div className="textSection">
                                        <a href="tel:+918075034668">+91 80750 34668</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Popupz title={showTitle} id="coverSuccess" className={showPopup} closePopup={() => setShowPopup("")}>
                <p>{message}</p>
                <a className='mainBtn' type='button' onClick={() => { setShowPopup(""); }}>Close</a>
            </Popupz>
        </>
    )
}

export default IdeaPitch