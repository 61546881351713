import React, { Component } from 'react';
import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';
const options = {
    loop: true,
    autoplay: true,
    dots: false,
    items: 8,
    margin: 35,
    responsive: {
      0:{
        items: 2
      },
      576:{
          items: 3
      },
      768:{
          items: 4
      },
      991:{
          items: 6
      },
      1200:{
        items: 8
      }
    }
  }
export class PartnerSlider extends Component{
    render(){
        return (
            <>
                <ReactOwlCarousel className='owl-theme' {...options}>
                    <div className='p-3'><div className='partner'><img src="/img/partners/arm.png" alt="arm" /></div></div>
                    <div className='p-3'><div className='partner'><img src="/img/partners/aws.jpeg" alt="aws" /></div></div>
                    <div className='p-3'><div className='partner'><img src="/img/partners/azur.jpeg" alt="azur" /></div></div>
                    <div className='p-3'><div className='partner'><img src="/img/partners/C.png" alt="c" /></div></div>
                    <div className='p-3'><div className='partner'><img src="/img/partners/CICD.jpeg" alt="cicd" /></div></div>
                    <div className='p-3'><div className='partner'><img src="/img/partners/database.jpg" alt="database" /></div></div>
                    <div className='p-3'><div className='partner'><img src="/img/partners/Docker.jpeg" alt="docker" /></div></div>
                    <div className='p-3'><div className='partner'><img src="/img/partners/gcp.jpeg" alt="gcp" /></div></div>
                    <div className='p-3'><div className='partner'><img src="/img/partners/git.jpeg" alt="git" /></div></div>
                    <div className='p-3'><div className='partner'><img src="/img/partners/grafana.jpeg" alt="grafana" /></div></div>
                    <div className='p-3'><div className='partner'><img src="/img/partners/jenkins.jpeg" alt="jenkins" /></div></div>
                    <div className='p-3'><div className='partner'><img src="/img/partners/kops.jpeg" alt="kops" /></div></div>
                    <div className='p-3'><div className='partner'><img src="/img/partners/Kubernetes.jpeg" alt="kubernetes" /></div></div>
                    <div className='p-3'><div className='partner'><img src="/img/partners/linux.png" alt="linux" /></div></div>
                    <div className='p-3'><div className='partner'><img src="/img/partners/python.jpeg" alt="python" /></div></div>
                    <div className='p-3'><div className='partner'><img src="/img/partners/terraform.jpeg" alt="terraform" /></div></div>
                </ReactOwlCarousel>
            </>
          )
    }
}

export default PartnerSlider