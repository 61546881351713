import React from 'react'

const Popupz = (props) => {
    const closePopup = () => {
        document.getElementById(props.id).classList.remove("active");
        if (props.onClose) {
            props.onClose();
        }
    }
  return (
    <>
        <div className={"bgOverlay " + props.className } id={props.id}>
            <div className="popupCard">
                <button className="closeBtn" onClick={closePopup}><i className="bi bi-x-lg"></i></button>
                <h2 className="popupHead">{props.title}</h2>
                <div className="maxHeight">
                {props.children}
                </div>
            </div>
        </div>
    </>
  )
}

Popupz.defaultProps = {
    title: 'Popup Title'
}

export default Popupz