import React, { useState, useEffect } from 'react';
import _default from 'react-share/lib/FacebookShareCount';
import http from "../../httpService";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

const Popupz = (props) => {
    return (
        <>
            <div className={"bgOverlay " + props.className} id={props.id}>
                <div className="popupCard">
                    <h2 className="popupHead">{props.title}</h2>
                    <div className="maxHeight">
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    )
}

function SuccessPopup(props) {
    return (
        <div>
            <Popupz title="" id="uploadResume" className={props.show ? 'active' : ''} onClose={props.onClose}>
                <div className="text-center">
                    <svg className='img-fluid mb-2' xmlns="http://www.w3.org/2000/svg" width="113.625" height="113.133" viewBox="0 0 113.625 113.133">
                        <g id="Icon_feather-check-circle" data-name="Icon feather-check-circle" transform="translate(-1.49 -1.43)" stroke='#00ff2a'>
                            <path id="Path_1341" data-name="Path 1341" d="M112.994,52.962v5.06A55,55,0,1,1,80.381,7.755" transform="translate(0 0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                            <path id="Path_1342" data-name="Path 1342" d="M85,6,30,61.052l-16.5-16.5" transform="translate(27.998 8.024)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                        </g>
                    </svg>
                    <p>{props.message}</p>
                    <a href="/" className='mainBtn'>Go to Home</a>
                </div>
            </Popupz>
        </div>
    )
}

const MyAccountEmployer = () => {

    let navigate = useNavigate();
    let [userDetails, setUserDetails] = useState(null);
    let [userImage, setUserImage] = useState('/img/user.svg');
    let [showPopup, setShowPopup] = useState(false);
    let [showmessage, setShowmessage] = useState('');
    let [userId, setUserId] = useState('');
    let [AccountOTPConfimation, setAccountOTPConfimation] = useState('');
    const [errors, setErrors] = useState({
        accountpasswordconfimation: undefined
    });

    useEffect(() => {
        http.get('api/edit-profile')
            .then((response) => {
                setUserDetails(response.data);
                setUserImage(response.data.avatar);
                IncompleteProfile(response.data);
            });
        window.scrollTo(0, 0);
        pagepermission();
    }, [])

    const pagepermission = async () => {
        let authData = localStorage.getItem('AuthData');
        if (authData) {
            try {
                const response = await http.get('api/userrole');
                if (!response.data.is_hirer) {
                    window.location.href = '/my-account';
                }
            } catch (exception) {

            }
        }
    }

    const IncompleteProfile = (userdata) => {
        if (!userdata.company_name || !userdata.company_description || !userdata.company_address || !userdata.first_name || !userdata.last_name || !userdata.phone || !userdata.work_phone) {
            let authData = localStorage.getItem('IncompleteProfile');
            if(!authData){
                document.getElementById("incompleteprofile").classList.add("active");
            }
        }
    }

    const IncompleteProfileClose = () => {
        localStorage.setItem('IncompleteProfile', JSON.stringify({
            user_name: userDetails.first_name,
            auth_role_id: userDetails.role_id,
        }));
        document.getElementById("incompleteprofile").classList.remove("active");
    }

    const ProfilePic = () => {
        const choosedFile = document.getElementById("profilePicInput").files[0];
        setUserImage(choosedFile);
        const reader = new FileReader();
        reader.addEventListener('load', function () {
            document.getElementById("profilePic").src = reader.result;
            document.getElementById("picUpdate").style.display = "block";
        });
        reader.readAsDataURL(choosedFile);
    }

    const imageSave = async () => {
        const formData = new FormData();
        formData.append('user_id', userDetails.user_id);
        formData.append('email', userDetails.email);
        formData.append('image', userImage);
        try {
            let response = await http.post("api/image-upload", formData);
            if (response.data) {
                setUserImage(response.data);
                document.getElementById("picUpdate").style.display = "none";
            }
        } catch (exception) {
            if (exception.response && exception.response.data && exception.response.data.errors) {
                console.log(exception.response.data.errors);
            }
        }

    }

    const logout = async (event) => {

        event.preventDefault();

        try {
            const response = await http.post('api/logout');

            if (response && response.data && response.data.message) {
                localStorage.clear();
                localStorage.removeItem('AuthData');
                window.location.href = '/login';
            }

        } catch (excpt) {

        }

    }

    const CreatePost = () => {
        let path = `/post-jobs`;
        navigate(path);
    }

    const deleteAccount = () => {
        document.getElementById("delete-account").classList.add("active");
    }

    const DeleteAccountClose = () => {
        document.getElementById("delete-account").classList.remove("active");
    }

    const RemoveAccount = async () => {
        try {
            const formData = new FormData();
            formData.append('email', userDetails.email);
            let response = await http.post("api/otp", formData);
            if (response && response.data) {
                document.getElementById("delete-account").classList.remove("active");
                document.getElementById("confirmation-password-field").classList.add("active");
            }
        } catch (exception) {
            if (exception.response && exception.response.data) {

            }
        }
    }
    const ConfirmationPasswordClose = () => {
        document.getElementById("confirmation-password-field").classList.remove("active");
    }
    const ConfirmationPassword = async (e) => {
        try {
            const formData = new FormData();
            formData.append('user_id', userDetails.user_id);
            formData.append('accountotpconfimation', AccountOTPConfimation);
            let response = await http.post("api/deleteaccount", formData);
            if (response && response.data) {
                localStorage.clear();
                localStorage.removeItem('AuthData');
                //window.location.href = '/';
                setUserId(userDetails.user_id);
                setShowPopup(true);
                setShowmessage(response.data.message);
                ConfirmationPasswordClose();
            }
        } catch (exception) {
            if (exception.response && exception.response.data) {
                setErrors({
                    accountpasswordconfimation: exception.response.data.accountpasswordconfimation
                });
            }
        }
    }

    const ConfirmationPasswordhandleSubmit = async(event) => {
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append('user_id', userDetails.user_id);
            formData.append('accountotpconfimation', AccountOTPConfimation);
            let response = await http.post("api/deleteaccount", formData);
            if (response && response.data) {
                localStorage.clear();
                localStorage.removeItem('AuthData');
                //window.location.href = '/';
                setUserId(userDetails.user_id);
                setShowPopup(true);
                setShowmessage(response.data.message);
                ConfirmationPasswordClose();
            }
        } catch (exception) {
            if (exception.response && exception.response.data) {
                setErrors({
                    accountpasswordconfimation: exception.response.data.accountpasswordconfimation
                });
            }
        }
    }

    return (
        <main>
            <section className="sectionPad myAccountCandidate" id="topInnerPage">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="mainTitle">
                                <h2>My Account</h2>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="glassCard userIntro">
                                <form className="zForm white">
                                    <div className="div85 text-center">
                                        <input type="file" name="profilePicInput" id="profilePicInput" accept="image/png, image/gif, image/jpeg" onChange={ProfilePic} />
                                        <label className="imgSq">
                                            <img src={userImage ? userImage : "/img/user.svg"} alt="img" id="profilePic" />
                                            <label htmlFor="profilePicInput" id="edit"><i className="fas fa-pencil-alt"></i></label>
                                        </label>
                                        <button type="button" id="picUpdate" className='mainBtn dNone' onClick={imageSave}>Save</button>
                                    </div>
                                </form>
                                <div className="text-center">
                                    <h3>{userDetails ? userDetails.first_name : ''} {userDetails ? userDetails.last_name : ''}</h3>
                                    { /* <p className="location"><i class="fas fa-map-marker-alt"></i>Serbia</p> */}
                                    <p className="designation">{userDetails ? userDetails.company_name : ''}</p>
                                </div>
                                <h4>Company Description</h4>
                                <p>{userDetails ? userDetails.company_description : ''}</p>
                                <h4>Technologies Used</h4>
                                <ul className='skills'>
                                    {userDetails && userDetails.usertechnology.map(usertechnology => <li>{usertechnology.technology}</li>)}
                                </ul>
                                <a href="/edit-profile-employer" className="mainBtn">Edit Profile</a>&nbsp;&nbsp;
                                {/* <a type='button' onClick={logout} className="altBtn">Sign Out</a> */}
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="glassCard">
                                <h4>Posted Jobs</h4>
                                {userDetails && userDetails.jobrequirement.map(jobdetails =>
                                    <div className="userDetails">
                                        <div className="d-flex">
                                            <h5>{jobdetails.title}</h5>
                                            <div>
                                                <p>No of Vacancies: {jobdetails.vacancy}</p>
                                                <p className="sal">{jobdetails.status}</p>
                                            </div>
                                        </div>
                                        <p style={{ 'display': '-webkit-box', '-webkit-line-clamp': '2', '-webkit-box-orient': 'vertical', 'overflow': 'hidden', 'text-overflow': 'ellipsis' }}>{jobdetails.description}</p>
                                        <p className="sal"><i class="fas fa-coins"></i>{jobdetails.salary}</p>
                                        <div className="text-end">
                                            <Link
                                                className='altBtn small ms-auto'
                                                to={{
                                                    pathname: "/edit-job/" + jobdetails.id
                                                }
                                                }
                                            >Edit Post</Link>
                                        </div>
                                    </div>
                                )}
                                {/* 
                                <div className="userDetails">
                                    <div className="d-flex">
                                        <h5>UI Designer</h5>
                                        <p>No of Vacancies: 3</p>
                                    </div>
                                    <p>Auditgear Technologies</p>
                                    <p className="sal"><i class="fas fa-coins"></i>3 - 5 lakhs per Annum</p>
                                    <div className="text-end"><a className="altBtn small ms-auto" href="/edit-job">Edit Post</a></div>
                                </div>
                                <div className="userDetails">
                                    <div className="d-flex">
                                        <h5>Frontend Developer</h5>
                                        <p>No of Vacancies: 2</p>
                                    </div>
                                    <p>Auditgear Technologies</p>
                                    <p className="sal"><i class="fas fa-coins"></i>3 - 5 lakhs per Annum</p>
                                    <div className="text-end"><a className="altBtn small ms-auto" href="/edit-job">Edit Post</a></div>
                                </div>
                                */}

                                <div className="mt-3 text-end"><a style={{ 'cursor': 'pointer' }} onClick={CreatePost} className='mainBtn'>Post More Jobs</a></div>
                            </div>
                            <div className="glassCard">
                                <h4>Requested Profiles</h4>
                                {userDetails && userDetails.viewprofilerequest.map(viewprofile => {
                                    let fulldate = viewprofile.created_at;
                                    let create_date = fulldate.split('T');
                                    return (
                                        <div className="userDetails">
                                            <div className="d-flex">
                                                <h5>{viewprofile.designation}</h5>
                                                <h5>{viewprofile.experience}</h5>
                                                <p>{viewprofile.status}</p>
                                            </div>
                                            <p>{create_date[0]}</p>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="glassCard">
                                <h4>Contact Info</h4>
                                <div className="userDetails">
                                    <div className="d-flex"><h5>Phone</h5></div>
                                    {userDetails ? <a href="tel:+919999999999">{userDetails.phone} (Mobile)</a> : ''}
                                    {userDetails ? <a href={userDetails.work_phone ? 'tel:' + userDetails.work_phone : 'tel:+919999999999'}>{userDetails.work_phone ? userDetails.work_phone + ' (Work)' : ''}</a> : ''}
                                </div>
                                <div className="userDetails">
                                    <div className="d-flex"><h5>Email</h5></div>
                                    <a href={userDetails ? 'mailto:' + userDetails.email : 'mailto:johndoe@email.com'}>{userDetails ? userDetails.email : ''}</a>
                                </div>
                                <div className="userDetails">
                                    <div className="d-flex"><h5>Address</h5></div>
                                    <p>{userDetails ? userDetails.company_address : ''}</p>
                                </div>
                            </div>
                            <div className="account-deletion">
                                <button type='button' onClick={deleteAccount}>Remove Account</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Popupz title="Delete account" id="delete-account">
                <p>Do you want to delete the account?</p>
                <button type='button' className="altBtn me-1" onClick={RemoveAccount}>Yes</button>
                <button type='button' className='mainBtn ms-1' onClick={DeleteAccountClose}>No</button>
            </Popupz>
            <Popupz title="OTP verification" id="confirmation-password-field">
                <form className="zForm white" onSubmit={ConfirmationPasswordhandleSubmit}>
                    <div className="inpGroup">
                        <label htmlFor="otp_confirmation">Enter OTP</label>
                        <input type="password" name="otp_confirmation" id='otp_confirmation' placeholder='OTP' onChange={(e) => setAccountOTPConfimation(e.target.value)} />
                        <div className="text-danger">{errors.accountotpconfimation}</div>
                    </div>
                    <div className="bottomSection">
                        <button type='button' className="altBtn me-1" onClick={ConfirmationPassword}>Confirm</button>
                        <button type='button' className="mainBtn me-1" onClick={ConfirmationPasswordClose}>Cancel</button>
                    </div>
                </form>
            </Popupz>
            <SuccessPopup
                message={showmessage}
                show={showPopup}
                vacancyId={userId}
                onClose={() => setShowPopup(false)}
            />
            <Popupz title="Incomplete Profile" id="incompleteprofile">
                <p>Please complete your profile to enhance your job search experience with Clockhash.</p>
                <a className='mainBtn mx-2' href='/edit-profile-employer' type='button'>Edit profile</a>
                <a className='altBtn' type='button' onClick={() => { IncompleteProfileClose() }}>Ignore</a>
            </Popupz>
        </main>
    )
}

export default MyAccountEmployer