import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import http from "../../httpService";
import Popupz from '../../components/Popupz';

const skillset = [
    'PHP',
    'Javascript',
    'HTML',
]

var skillCount = 0;

const SkillField = (props) => {
    const DeleteSkills = (e) => {
        props.deleteSkill(props.skillIndex);
    }
    return (

        <div className="row skills" id={"skills" + props.dataCount}>
            <div className="col-md-10">
                <div className="inpGroup">
                    <label htmlFor="skill-name">Technologies name</label>
                    <Autocomplete
                        value={props.technology}
                        disablePortal
                        id={"skill-name-" + props.dataCount}
                        options={skillset}
                        name="technology"
                        required
                        freeSolo
                        renderInput={(params) => <TextField
                            {...params}
                            onKeyUp={e => {
                                // if (e.code === 'enter' && e.target.value) {
                                props.onChange(props.skillIndex, 'name', e.target.value);
                                // }
                            }}
                        />}
                        onChange={(event, newValue) => {
                            console.log('newValue')
                            console.log(newValue)
                            props.onChange(props.skillIndex, 'name', newValue);
                        }}
                    />
                </div>
            </div>
            <div className="col-lg-1 col-md-2 align-content-end d-grid">
                <div className="inpGroup">
                    <button className="altBtn w-100" type="button" onClick={DeleteSkills} data_id={"skills" + props.dataCount}><i className="fas fa-trash"></i></button>
                </div>
            </div>
        </div>
    )
}

class EditProfileEmployer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // application_id: "",
            first_name: undefined,
            last_name: undefined,
            company_name: undefined,
            company_description: undefined,
            avatar: undefined,
            // auth_token: "",
            phone: undefined,
            work_phone: undefined,
            // skills: "",
            email: undefined,
            company_address: undefined,
            skills: [],
            showSuccess: false,
            inputList: [],
            errors: {
                first_name: undefined,
                last_name: undefined,
                phone: undefined,
                work_phone: undefined,
                email: undefined,
                company_address: undefined,
                company_name: undefined,
                company_description: undefined,
            }
        };
    }

    componentDidMount() {
        this.getEditProfileDetails();
        this.pagepermission();
    }

    pagepermission = async () => {
        let authData = localStorage.getItem('AuthData');
        if (authData) {
            try {
                const response = await http.get('api/userrole');
                if (!response.data.is_hirer) {
                    window.location.href = '/my-account';
                }
            } catch (exception) {

            }
        }
    }

    getEditProfileDetails = async () => {
        const response = await http.get('api/edit-profile');

        if (response.data.status && response.data.status === 'success') {
            this.setState({
                first_name: response.data.first_name,
                last_name: response.data.last_name,
                company_name: response.data.company_name ? response.data.company_name : "",
                company_description: response.data.company_description ? response.data.company_description : "",
                company_address: response.data.company_address ? response.data.company_address : "",
                email: response.data.email,
                phone: response.data.phone ? response.data.phone : "",
                skills: response.data.usertechnology,
                avatar: response.data.avatar,
                work_phone: response.data.work_phone ? response.data.work_phone : ""
            });
        }
    }

    ProfilePic = () => {
        const choosedFile = document.getElementById("profilePicInput").files[0];
        this.setState({
            avatar: choosedFile
        });
        const reader = new FileReader();
        reader.addEventListener('load', function () {
            document.getElementById("profilePic").src = reader.result;
        });
        reader.readAsDataURL(choosedFile);
    }

    AddSkill = () => {
        this.setState({
            skills: this.state.skills.concat({}),
        })
    }
    handleSkillChange = (skillIndex, attributeName, value) => {

        const skills = this.state.skills;

        if (skills.length > skillIndex) {

            skills[skillIndex][attributeName] = value;

            this.setState({
                skills
            });
        }
    }
    deleteSkill = (skillIndex) => {
        let skills = this.state.skills;

        if (skills.length > skillIndex) {

            skills.splice(skillIndex, 1);

            this.setState({
                skills
            });
        }
    }

    handleInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleOnChange = (value, data, event, formattedValue) => {
        //console.log(data.dialCode);
        this.setState({
            phone: event.target.value,
        });
    }

    handleOnChangework = (value, data, event, formattedValue) => {
        this.setState({
            work_phone: event.target.value,
        });
    }

    toggleDivFunc = (e) => {
        var k = e.target.getAttribute("data-id");
        document.getElementById(k).classList.toggle("active");
        e.target.classList.toggle("active");
    }

    saveProfile = async () => {

        const formData = new FormData();
        formData.append('jobs_type', '7');
        formData.append('first_name', this.state.first_name);
        formData.append('last_name', this.state.last_name);
        formData.append('company_name', this.state.company_name);
        formData.append('company_description', this.state.company_description);
        formData.append('phone', this.state.phone);
        formData.append('email', this.state.email);
        formData.append('avatar', this.state.avatar);
        formData.append('work_phone', this.state.work_phone);
        formData.append('company_address', this.state.company_address);
        formData.append('usertechnology', JSON.stringify(this.state.skills));


        try {
            let response = await http.post("api/update-profile", formData);

            if (response.data.status && response.data.status === 'success') {

                this.setState({
                    showSuccess: true,
                });
                // window.location.href = '/my-account';
            }
        } catch (exception) {

            if (exception.response && exception.response.data) {
                this.setState({
                    errors: exception.response.data
                })
                console.log(exception.response.data);
            }
        }


    }

    render() {

        const Popupz = (props) => {
            const closePopup = () => {
                document.getElementById(props.id).classList.remove("active");
                if (props.onClose) {
                    props.onClose();
                }
            }
          return (
            <>
                <div className={"bgOverlay " + props.className } id={props.id}>
                    <div className="popupCard">
                        <button className="closeBtn" onClick={closePopup}><i className="bi bi-x-lg"></i></button>
                        <h2 className="popupHead">{props.title}</h2>
                        <div className="maxHeight">
                        {props.children}
                        </div>
                    </div>
                </div>
            </>
          )
        }
        
        return (
            <>
                <main>
                    <section className="sectionPad editProfile" id="topInnerPage">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <div className="mainTitle">
                                        <h2>Edit Profile</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <form className="zForm white">
                                        <div className="glassCard userIntro mb-3">
                                            <div className="div85">
                                                <input type="file" name="profilePicInput" id="profilePicInput"
                                                    accept="image/png, image/gif, image/jpeg" onChange={this.ProfilePic} />
                                                <label className="imgSq">
                                                    <img src={this.state.avatar ? this.state.avatar : "/img/user.svg"}
                                                        alt="img" id="profilePic" />
                                                    <label htmlFor="profilePicInput" id="edit"><i
                                                        className="fas fa-pencil-alt"></i></label>
                                                </label>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="inpGroup">
                                                        <label htmlFor="fname">Firstname*</label>
                                                        <input type="text" name="first_name" id='fname' placeholder='John' required value={this.state.first_name} onChange={this.handleInputChange} />
                                                        <div className="text-danger">{this.state.errors.first_name}</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="inpGroup">
                                                        <label htmlFor="lname">Lastname*</label>
                                                        <input type="text" name="last_name" id='lname' placeholder='Doe' required value={this.state.last_name} onChange={this.handleInputChange} />
                                                        <div className="text-danger">{this.state.errors.last_name}</div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="inpGroup">
                                                        <label htmlFor="desig">Company Name*</label>
                                                        <input type="text" name="company_name" id='company_name' placeholder='Company Name' required value={this.state.company_name} onChange={this.handleInputChange} />
                                                        <div className="text-danger">{this.state.errors.company_name}</div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="inpGroup">
                                                        <label htmlFor="bio">Company Description*</label>
                                                        <textarea name="company_description" id="company_description" rows="10" required value={this.state.company_description} onChange={this.handleInputChange}></textarea>
                                                        <div className="text-danger">{this.state.errors.company_description}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="glassCard userIntro mb-3">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="d-flex justify-content-between mb-2"><h4>Technologies Used</h4> <button type="button" className="altBtn toggleDivBtn" data-id="skillDivRow" onClick={this.toggleDivFunc}><i className="fas fa-angle-down"></i></button></div>
                                                </div>
                                            </div>
                                            <div className="row toggleDiv" id="skillDivRow">
                                                <div className="col-12" id="skills">
                                                    {this.state.skills.map((skill, skillIndex) => <SkillField {...skill} skillIndex={skillIndex} key={skillIndex} onChange={this.handleSkillChange} deleteSkill={this.deleteSkill} />)}
                                                </div>
                                                <div className="col-12 text-end">
                                                    <button className="mainBtn" type='button' onClick={this.AddSkill}>Add Technologies</button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="glassCard userIntro mb-3">
                                            <div className="row mb-4">
                                                <div className="col-12">
                                                    <h4>Educational Qualifications</h4>
                                                </div>
                                                <div className="col-12" id="education">
                                                    {this.state.inputList}
                                                </div>
                                                <div className="col-12 text-end">
                                                    <button className="mainBtn" type='button' onClick={this.AddEducation}>Add
                                                        Education</button>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="glassCard userIntro mb-3">
                                            <div className="row mb-4">
                                                <div className="col-12">
                                                    <h4>Work Experience</h4>
                                                </div>
                                                <div className="col-12" id="workexperience">
                                                    {this.state.inputList2}
                                                </div>
                                                <div className="col-12 text-end">
                                                    <button className="mainBtn" type='button' onClick={this.AddWork}>Add Work Experience</button>
                                                </div>
                                            </div>
                                            </div> */}
                                        <div className="glassCard userIntro">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h4>Contact Info</h4>
                                                </div>
                                                <div className="col-12" id="workexperience">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="inpGroup">
                                                                <label htmlFor="phone">Phone(Mobile)*</label>
                                                                <PhoneInput placeholder="+91 XXXXX-XXXXX" name="phone" required value={this.state.phone} onChange={this.handleOnChange} country={'in'} />
                                                                <div className="text-danger">{this.state.errors.phone}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="inpGroup">
                                                                <label htmlFor="phone">Phone(Work)*</label>
                                                                <PhoneInput placeholder="+91 XXXXX-XXXXX" name="work_phone" value={this.state.work_phone} onChange={this.handleOnChangework} country={'in'} />
                                                                <div className="text-danger">{this.state.errors.work_phone}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="inpGroup">
                                                                <label htmlFor="email">Email*</label>
                                                                <input type="text" name="email" id='email' placeholder='johndoe@email.com' required value={this.state.email} onChange={this.handleInputChange} />
                                                                <div className="text-danger">{this.state.errors.email}</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="inpGroup">
                                                                <label htmlFor="addr">Address*</label>
                                                                <textarea name="company_address" id="addr" rows="10" placeholder="Address" required onChange={this.handleInputChange} value={this.state.company_address}></textarea>
                                                                <div className="text-danger">{this.state.errors.company_address}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* {this.state.inputList4} */}
                                            <div className="row">
                                                {/* <div className="col-12 text-end">
                                                    <button className="mainBtn mb-2" type='button' onClick={this.AddLink}>Add Link</button>
                                                </div> */}
                                                <div className="col-12 text-end">
                                                    <a href="/my-account-employer" className="altBtn">Cancel</a> &nbsp; <button className="mainBtn" onClick={this.saveProfile} type='button'>Save</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Popupz title="" id="uploadResume" className={this.state.showSuccess ? "active" : ''}>
                    <div id="resumeSuccess" className="active">
                        <div className="text-center">
                            <svg className='img-fluid mb-2' xmlns="http://www.w3.org/2000/svg" width="113.625" height="113.133" viewBox="0 0 113.625 113.133">
                                <g id="Icon_feather-check-circle" data-name="Icon feather-check-circle" transform="translate(-1.49 -1.43)" stroke='#00ff2a'>
                                    <path id="Path_1341" data-name="Path 1341" d="M112.994,52.962v5.06A55,55,0,1,1,80.381,7.755" transform="translate(0 0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                    <path id="Path_1342" data-name="Path 1342" d="M85,6,30,61.052l-16.5-16.5" transform="translate(27.998 8.024)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                                </g>
                            </svg>
                            <p>Profile updated successfully</p>
                            <a href="/my-account-employer" className='mainBtn'>Go To Profile</a>
                        </div>
                    </div>
                </Popupz>
            </>
        )
    }
}

export default EditProfileEmployer