import React, { Component } from 'react';
import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import http from '../httpService';

const options = {
  loop: true,
  nav: false,
  autoplay: true,
  dots: false,
  autoplayTimeout: 1500,
  autoplaySpeed: 1000,
  margin: 80,
  items: 2.5,
  responsive: {
    0: {
      items: 1,
      margin: 24
    },
    350: {
      items: 2,
      margin: 24
    },
    576: {
      items: 3,
      margin: 24
    },
    768: {
      items: 4,
      margin: 24
    },
    991: {
      items: 2.5,
      margin: 24
    },
    1000: {
      items: 2.5
    }
  }
}
export class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {
      team: []
    }
  }
  componentDidMount() {
    this.getTeamDetails();
  }

  getTeamDetails = async () => {
    const response = await http.get('api/team');
    if (response.data.team) {
      this.setState({
        team: response.data.team
      })
    }
  }
  render() {
    return (
      <>
        {this.state.team.map((details) => <div className="developerCard">
          <div className="imgSq">
            <img src={details.photo ? details.photo : 'img/user.svg'} alt="developer" />
          </div>
          <div className="textSection">
            <h4>{details.name}</h4>
            <span><i className="fas fa-code"></i> {details.designation}</span>
          </div>

        </div>)}
      </>
    )
  }
}
export class DeveloperSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      team: []
    }
  }

  componentDidMount() {
    this.getTeamDetails();
  }

  getTeamDetails = async () => {
    const response = await http.get('api/team');
    if (response.data.team) {
      this.setState({
        team: response.data.team
      })
    }
  }
  render() {
    return (
      <>
        <ReactOwlCarousel className="owl-theme" {...options}>
          <Team />
        </ReactOwlCarousel>
      </>
    )
  }
}


export default DeveloperSlider  