
import React, { Component } from "react";
import http from "../httpService";

export default class LoginLogoutButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            authData: null,
            userdetail: undefined,
        };
    }

    componentDidMount() {

        let authData = localStorage.getItem('AuthData');

        if (authData) {
            authData = JSON.parse(authData);
            if (authData) {
                this.setState({
                    authData: authData,
                });
            }
        }

        this.UserData();
    }

    UserData = async () => {

        try {
            let response = await http.post("api/profile");
            this.setState({
                userdetail: response.data.application
            })
        } catch (exception) {
            if (exception.response.data.message == "Unauthenticated." && this.state.authData != null) {
                console.log(this.state.authData);
                localStorage.removeItem('AuthData');
                window.location.href = '/login';
            }
        }
    }
    /*
    myaccount = async () => {
        try {
            let response = await http.post("api/pagepermission");
            if (response.data.user && response.data.is_hirer) {
                window.location.href = '/my-account-employer';
            }
            if(response.data.user && response.data.is_job_seeker){
                window.location.href = '/my-account';
            }

        } catch (exception) {
            if (exception.response.data.message == "Unauthenticated.") {
                //console.log(exception.response.data.message);
            }
        }
    }
    */
    myaccount = async () => {
        let authData = localStorage.getItem('AuthData');
        if (authData) {
            try {
                const response = await http.get('api/userrole');
                if (response.data.is_hirer) {
                    window.location.href = '/my-account-employer';
                }
                if (response.data.is_job_seeker) {
                    window.location.href = '/my-account';
                }
            } catch (exception) {
                if (exception.response.data.message == "Unauthenticated.") {
                    //console.log(exception.response.data.message);
                }
            }
        }
    }
    logout = async (event) => {

        event.preventDefault();

        try {
            const response = await http.post('api/logout');

            if (response && response.data && response.data.message) {
                localStorage.clear();
                localStorage.removeItem('AuthData');
                window.location.href = '/login';
            }

        } catch (excpt) {

        }

    }
    render() {
        return this.state.authData ?
            <>
                <a className="mainBtn" onClick={this.myaccount} style={{ 'cursor': 'pointer' }}>My Account</a><span className="singleSpace">&nbsp;</span>
                <button className="altBtn logOutBtn" onClick={this.logout}>Logout</button>
            </> :
            <a className="altBtn mainBtn" href="/login">Login/SignUp</a>
    }
}