import React, { Component } from "react";
// import { useTranslation } from "react-i18next";
import http from '../../httpService';
import { withTranslation } from 'react-i18next';


const initialState = {
    fname: "",
    eMail: "",
    sub: "",
    msg: "",
    sendMessage: false,
    errors: {},
    mailsend: false
};

class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value }, this.isValid);
    };

    ContactFormSend = async () => {
        this.setState({
            sendMessage: true,
        });

        let errors = {};
        let isValid = true;

        if (!this.state.fname) {
            isValid = false;
            errors["name"] = "Please enter your name.";
        }

        if (!this.state.sub) {
            isValid = false;
            errors["sub"] = "Please enter subject.";
        }

        if (!this.state.eMail) {
            isValid = false;
            errors["email"] = "Please enter your email Address.";
        }

        if (typeof this.state.eMail !== "undefined") {

            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(this.state.eMail)) {
                isValid = false;
                errors["email"] = "Please enter valid email address.";
            }
        }

        if (isValid) {
            const fname = this.state.fname;
            const eMail = this.state.eMail;
            const sub = this.state.sub;
            const msg = this.state.msg;
            /*
            var data = {
                name: this.state.fname,
                email: this.state.eMail,
                subject: this.state.sub,
                message: this.state.msg,
            }*/
            const formData = new FormData();
            formData.append('name', this.state.fname);
            formData.append('email', this.state.eMail);
            formData.append('subject', this.state.sub);
            formData.append('message', this.state.msg);
            try {
                let response = await http.post('api/contact-form/mail', formData);
                console.log(response.data);
                if(response.data.status === 'success'){
                    this.setState({
                        sendMessage: false,
                        fname: "",
                        eMail: "",
                        sub: "",
                        msg: "",
                        mailsend: true,
                        errors: ""
                    });
                    const timer = setTimeout(() => this.setState({ mailsend: false }), 10000);
                }
            } catch (exception) {
                if (!exception.response.data.errors) {
                    console.log(exception.response.data.errors);
                }
            }
        }
        else {
            this.setState({
                errors: errors
            });
        }
    }

    render() {

        const { t } = this.props;

        return (
            <main>
                <section className="sectionPad contact" id="topInnerPage">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="mainTitle">
                                    <h2>Contact</h2>
                                </div>
                            </div>
                        </div>
                        <div className="glassSection">
                            <div className="row">
                                <div className="col-12">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d125745.01134913055!2d76.2429749!3d9.9728723!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1645184970872!5m2!1sen!2sin" style={{ border: 0 }} allowfullscreen="" loading="lazy"></iframe>
                                </div>
                                <div className="col-md-3">
                                    <div className="addrDetails">
                                        <i className="fas fa-map-marker-alt"></i>
                                        <div className="textSection">
                                            <p>503, 5th Floor, Trans Asian Corporate Park,<br />
                                                Seaport Airport Road,<br />
                                                Chittethukkara, Kakkanad<br />
                                                Cochin - 682 037<br />
                                                Kerala</p>
                                        </div>
                                    </div>
                                    <div className="addrDetails align-items-center">
                                        <i className="fas fa-envelope"></i>
                                        <div className="textSection">
                                            <a href="mailto:info@clockhash.com">info@clockhash.com</a>
                                        </div>
                                    </div>
                                    {/* <div className="addrDetails align-items-center">
                                    <i className="fas fa-phone-alt"></i>
                                        <div className="textSection">
                                            <a href="tel:+91 XXXX XXXX XX">+91 XXXX XXXX XX</a>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="col-md-9">
                                    <form action="" className="zForm white">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="inpGroup">
                                                    <label htmlFor="fname">Name</label>
                                                    <input type="text" name="fname" id='fname' value={this.state.fname} placeholder='John Doe' onChange={this.handleChange} />
                                                </div>
                                                <div className="text-danger">{this.state.errors.name}</div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="inpGroup">
                                                    <label htmlFor="eMail">Email</label>
                                                    <input type="email" name="eMail" id='eMail' value={this.state.eMail} placeholder='johndoe@email.com' onChange={this.handleChange} />
                                                </div>
                                                <div className="text-danger">{this.state.errors.email}</div>
                                            </div>
                                            <div className="col-12">
                                                <div className="inpGroup">
                                                    <label htmlFor="sub">Subject</label>
                                                    <input type="text" name="sub" id='sub' value={this.state.sub} placeholder='Subject' onChange={this.handleChange} />
                                                </div>
                                                <div className="text-danger">{this.state.errors.sub}</div>
                                            </div>
                                            <div className="col-12">
                                                <div className="inpGroup">
                                                    <label htmlFor="msg">Message</label>
                                                    <textarea name="msg" id="msg" rows="10" value={this.state.msg} placeholder="Message" onChange={this.handleChange}></textarea>
                                                </div>
                                            </div>
                                            <div className="col-9 text-end mt-3">
                                                {this.state.mailsend ?
                                                    <div className="alert alert-success alert-dismissible" role="alert">
                                                        {t(" Thank you!,  We've received your message and will get back to you")}
                                                    </div>
                                                    : ""
                                                }
                                            </div>
                                            <div className="col-3 text-end mt-4">
                                                <button type="button" className='mainBtn' onClick={this.ContactFormSend} >
                                                    {this.state.sendMessage &&
                                                        <div className="spinner-grow spinner-grow-sm mr-5" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    }
                                                    Send Message
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        )
    }
};
export default withTranslation()(Contact);
