import React from 'react'

const BlogThree = () => {
  return (
    <main>
        <section className="sectionPad blogDetail" id="topInnerPage">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1>How hiring a Remote Team can be Effective for your Business!</h1>
                        <img src="https://images.unsplash.com/photo-1521791136064-7986c2920216?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80" alt="blog" />
                        <p>Work from home employment or remote jobs is in high demand post the pandemic. Data from Statista shows that- there is a tremendous increase in remote work across the globe. Upwork predicts that the percentage might come down, but at least 22% will still be remote workers by 2025.</p>
                        <p>Hiring and organising a remote team can be a challenging process. But, it can be one of the best decisions ever taken for your company. Technology has also evolved to adapt to the new norms of the business processes, making it easier for businesses to work with remote teams. Companies are now planning to develop a hybrid business model that requires only certain employees to be present at the site. Here are some benefits of hiring a remote workforce that can prove effective for your business.</p>
                        <h2>Low Business Expense</h2>
                        <p>Many companies are adopting remote teams to cut short their business expenses. Companies can conserve finances on some of the expenses associated with on-site operations. Depending on the company size- this could be a significant amount of savings.</p>
                        <h2>Greater Access to applicants</h2>
                        <p>Eliminating the geographic constraints allows companies to find the best-skilled personnel. Remote employment widens the scope and opens up the opportunity to a broader audience. Clockhash helps its clients by finding the right match from our exclusive community. We also cater to the clients' unique skill needs and help them find the right candidate with our ace team of recruiters.</p>
                        <h2>Increase in productivity</h2>
                        <p>Research indicates that remote employee performs an additional 1.4 days of work/month than inoffice workers, which is closely 17 more workdays a year. Statistics show us that remote work is more effective if it is put into practice the right way.</p>
                        <h2>Bottomline</h2>
                        <p>Embracing a remote working guideline can save companies capital by removing the demand for expensive office space while entitling workers the freedom to create their schedules and operate from wherever they please. It can be a win-win situation. Clockhash helps companies by associating with them to realise their requirements by matching the correct skilled candidates.</p>
                    </div>
                </div>
            </div>
        </section>
    </main>
  )
}

export default BlogThree