import React from 'react';
import BlogList from '../../components/BlogList';

const AboutUs = () => {

    return (
        <main>
            <section id="aboutUs">
                <section id="topInnerPage" className="sectionPad">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="mainTitle">
                                    <h2>About Us</h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="glassCard">
                                    <div className="aboutText">
                                        <h5>We'll help you find the most skilled, seasoned &amp; highly qualified professionals to forge your future firm!</h5>
                                        <p>During the Covid pandemic, we saw that local European and Western companies had trouble finding skilled and qualified professionals. As a result, the pandemic's impact forced companies to work remotely. It encouraged them to collaborate and work with offshore firms. But, the biggest challenge for them was finding these skilled and qualified professionals. So, we, Clockhash saw this as an ideal opportunity to kick-start and launch our venture.</p>
                                            <h4>What We Do</h4>
                                            <p>As a trusted third party, we conduct an executive search with local representatives to explore and find your dedicated professional. Then, the selected remote professional (or team) collaborates with your local team to help you reach your goals. We also have a highly competitive pricing model, making doing business with us even more attractive. We are here to build and maintain a long-term, fruitful relationship.</p>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-md-4">
                                            <i className="fas fa-eye"></i>
                                            <h4>Our Vision</h4>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nunc aliquet bibendum enim facilisis gravida. Massa tincidunt dui ut ornare lectus sit amet est.</p>
                                        </div>
                                        <div className="col-md-4">
                                            <i className="fas fa-hands"></i>
                                            <h4>Our Values</h4>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nunc aliquet bibendum enim facilisis gravida. Massa tincidunt dui ut ornare lectus sit amet est.</p>
                                        </div>
                                        <div className="col-md-4">
                                            <i className="fas fa-mountain"></i>
                                            <h4>Our Mission</h4>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nunc aliquet bibendum enim facilisis gravida. Massa tincidunt dui ut ornare lectus sit amet est.</p>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-12 sectionPad pb-0">
                                <div className="mainTitle">
                                    <h2>Why Clockhash</h2>
                                </div>
                                <div className="row">
                                                <div className="col-md-6 col-lg-4 col-xl-3 d-grid mb-3">
                                                    <div className="glassCard">
                                                    <h5>Only Get the Best</h5>
                                                    <p>ClockHash is a powerful platform that lets you manage and supervise all screening processes step by step. Nothing gets overlooked here.</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-3 d-grid mb-3">
                                                    <div className="glassCard">
                                                    <h5>Strict Screening Process</h5>
                                                    <p>Our prime priority is only finding the best for you. So we always make sure to choose the best of the best for you.</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-3 d-grid mb-3">
                                                    <div className="glassCard">
                                                    <h5>Access a Global Talent Pool</h5>
                                                    <p>Wherever the best be, we've got you covered for finding top talent from all over the world. We're here to take care of the rest.</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-3 d-grid mb-3">
                                                    <div className="glassCard">
                                                    <h5>Professional Assistance</h5>
                                                    <p>You are not alone in searching for a perfect candidate. An experienced ClockHash professional will guide each selection stage even if you are not there. Let us handle the work and the worries.</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-3 d-grid mb-3">
                                                    <div className="glassCard">
                                                    <h5>Best For Getting Started</h5>
                                                    <p>ClockHash is an ideal platform for large and small scale projects requiring highly experienced professionals. So whatever your venture be, we'll help you pick the right talent.</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-3 d-grid mb-3">
                                                    <div className="glassCard">
                                                    <h5>Best Value</h5>
                                                    <p>ClockHash always ensures to offer a very competitive pricing model, making doing business with us even more fun. As a result, you'll get the best service for the best price.</p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-3 d-grid mb-3">
                                                    <div className="glassCard">
                                                    <h5>Multiple Demands, One Solution</h5>
                                                    <p>Whether you want to extend your team or hire top professionals, or you are building an entirely remote office and looking for freelancers, we've got your back!</p>
                                                    </div>
                                                </div>
                                            </div>
                            </div>
                            <div className="col-12">
                                <div className="glassCard">
                                    <p className="mb-0">Whatever your requirement be, a professional and extremely talented team of experts are here to help you forge the future you've always desired! So connect with us and hire skilled, seasoned, top-notch professionals to build a world-class team. So yes, your dreams do matter to us!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            {/* <section id="ourTeam" className="sectionPad">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="mainTitle">
                                <h2>Our Team</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-lg-3">
                            <div className="developerCard">
                                <div className="imgSq">
                                    <img src="https://images.pexels.com/photos/2379005/pexels-photo-2379005.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" alt="developer" />
                                    <ul className="socialList">
                                        <li><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="/"><i className="fab fa-instagram"></i></a></li>
                                        <li><a href="/"><i className="fab fa-linkedin-in"></i></a></li>
                                    </ul>
                                </div>
                                <div className="textSection">
                                    <h4>John Doe</h4>
                                    <span>CEO</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3">
                            <div className="developerCard">
                                <div className="imgSq">
                                    <img src="https://images.pexels.com/photos/935969/pexels-photo-935969.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="developer" />
                                    <ul className="socialList">
                                        <li><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="/"><i className="fab fa-instagram"></i></a></li>
                                        <li><a href="/"><i className="fab fa-linkedin-in"></i></a></li>
                                    </ul>
                                </div>
                                <div className="textSection">
                                    <h4>John Doe</h4>
                                    <span>Director</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3">
                            <div className="developerCard">
                                <div className="imgSq">
                                    <img src="https://images.pexels.com/photos/2128819/pexels-photo-2128819.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="developer" />
                                    <ul className="socialList">
                                        <li><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="/"><i className="fab fa-instagram"></i></a></li>
                                        <li><a href="/"><i className="fab fa-linkedin-in"></i></a></li>
                                    </ul>
                                </div>
                                <div className="textSection">
                                    <h4>John Doe</h4>
                                    <span>CMO</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3">
                            <div className="developerCard">
                                <div className="imgSq">
                                    <img src="https://images.pexels.com/photos/2816544/pexels-photo-2816544.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="developer" />
                                    <ul className="socialList">
                                        <li><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="/"><i className="fab fa-instagram"></i></a></li>
                                        <li><a href="/"><i className="fab fa-linkedin-in"></i></a></li>
                                    </ul>
                                </div>
                                <div className="textSection">
                                    <h4>John Doe</h4>
                                    <span>CFO</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3">
                            <div className="developerCard">
                                <div className="imgSq">
                                    <img src="https://images.pexels.com/photos/2091327/pexels-photo-2091327.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="developer" />
                                    <ul className="socialList">
                                        <li><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="/"><i className="fab fa-instagram"></i></a></li>
                                        <li><a href="/"><i className="fab fa-linkedin-in"></i></a></li>
                                    </ul>
                                </div>
                                <div className="textSection">
                                    <h4>John Doe</h4>
                                    <span>CTO</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3">
                            <div className="developerCard">
                                <div className="imgSq">
                                    <img src="https://images.pexels.com/photos/3755913/pexels-photo-3755913.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="developer" />
                                    <ul className="socialList">
                                        <li><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="/"><i className="fab fa-instagram"></i></a></li>
                                        <li><a href="/"><i className="fab fa-linkedin-in"></i></a></li>
                                    </ul>
                                </div>
                                <div className="textSection">
                                    <h4>John Doe</h4>
                                    <span>Director</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3">
                            <div className="developerCard">
                                <div className="imgSq">
                                    <img src="https://images.pexels.com/photos/1878522/pexels-photo-1878522.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="developer" />
                                    <ul className="socialList">
                                        <li><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="/"><i className="fab fa-instagram"></i></a></li>
                                        <li><a href="/"><i className="fab fa-linkedin-in"></i></a></li>
                                    </ul>
                                </div>
                                <div className="textSection">
                                    <h4>John Doe</h4>
                                    <span>Director</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-3">
                            <div className="developerCard">
                                <div className="imgSq">
                                    <img src="https://images.pexels.com/photos/839011/pexels-photo-839011.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="developer" />
                                    <ul className="socialList">
                                        <li><a href="/"><i className="fab fa-facebook-f"></i></a></li>
                                        <li><a href="/"><i className="fab fa-instagram"></i></a></li>
                                        <li><a href="/"><i className="fab fa-linkedin-in"></i></a></li>
                                    </ul>
                                </div>
                                <div className="textSection">
                                    <h4>John Doe</h4>
                                    <span>Director</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            </section>
            <section id="blog" className="sectionPad abt">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <div className="mainTitle">
                    <h2>Latest Blogs</h2>
                    </div>
                </div>
                </div>
            </div>
            <BlogList/>
            <div className="text-center pb-3 position-relative z1"><a href="/blog" className="mainBtn">View All</a></div>
            </section>
        </main>
    )
};

export default AboutUs;
