import React from 'react'
const MailVerification = () => {
  return (
    <main>
        <section className="sectionPad login" id="topInnerPage">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="mainTitle">
                            <h2>Verify</h2>
                        </div>
                    </div>
                    <div className="col-md-8 col-lg-6">
                        <div className="glassCard p-4">
                            <div className="text-center mb-3">
                                <img src="/img/mail-icon.svg" alt="Mail Icon" className='img-fluid' />
                            </div>
                            <p className='text-white'>A Verification link has been sent to your registered email id open the email app and verify your email to continue</p>
                            <a className="mainBtn" href='/login'>Login</a>&nbsp;&nbsp;<a className="altBtn" href='/'>Go to Homepage</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
  )
}

export default MailVerification