import React, { useState, useEffect } from 'react';
import _default from 'react-share/lib/FacebookShareCount';
import http from "../../httpService";
import Popupza from '../../components/Popupz';
import { testModeAPI } from 'react-ga';


const Popupz = (props) => {
    return (
        <>
            <div className={"bgOverlay " + props.className} id={props.id}>
                <div className="popupCard">
                    <h2 className="popupHead">{props.title}</h2>
                    <div className="maxHeight">
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    )
}

function SuccessPopup(props) {
    return (
        <div>
            <Popupz title="" id="uploadResume" className={props.show ? 'active' : ''} onClose={props.onClose}>
                <div className="text-center">
                    <svg className='img-fluid mb-2' xmlns="http://www.w3.org/2000/svg" width="113.625" height="113.133" viewBox="0 0 113.625 113.133">
                        <g id="Icon_feather-check-circle" data-name="Icon feather-check-circle" transform="translate(-1.49 -1.43)" stroke='#00ff2a'>
                            <path id="Path_1341" data-name="Path 1341" d="M112.994,52.962v5.06A55,55,0,1,1,80.381,7.755" transform="translate(0 0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                            <path id="Path_1342" data-name="Path 1342" d="M85,6,30,61.052l-16.5-16.5" transform="translate(27.998 8.024)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                        </g>
                    </svg>
                    <p>{props.message}</p>
                    <a href="/" className='mainBtn'>Go to Home</a>
                </div>
            </Popupz>
        </div>
    )
}

const MyAccountCandidate = () => {
    let [userDetails, setUserDetails] = useState(null);
    let [userImage, setUserImage] = useState('/img/user.svg')
    const [resume, setResume] = useState('');
    const [resumeKey, setResumeKey] = useState(1);
    const [savingResume, setSavingResume] = useState(false);
    const [downloadResume, setDownloadResume] = useState(false);
    const [coverletter, setConverletter] = useState('');
    const [converletterkey, setConverletterkey] = useState(1);
    const [savingConverletter, setSavingConverletter] = useState(false);
    const [downloadConverletter, setDownloadConverletter] = useState(false);
    const [errors, setErrors] = useState({
        resume: undefined,
        accountotpconfimation: undefined,
        coverletter: undefined
    });
    let [showPopup, setShowPopup] = useState(false);
    let [showmessage, setShowmessage] = useState('');
    let [userId, setUserId] = useState('');
    let [AccountOTPConfimation, setAccountOTPConfimation] = useState('');

    useEffect(() => {
        http.get('api/edit-profile')
            .then((response) => {
                setUserDetails(response.data);
                setUserImage(response.data.avatar);
                setDownloadConverletter(response.data.cover_letter);
                let resume = response.data;
                if (resume['userResume'][0]) {
                    setDownloadResume(resume['userResume'][0]);
                }
                IncompleteProfile(response.data);
            });
        pagepermission();
    }, [])

    const IncompleteProfile = (userdata) => {
        if (!userdata.phone || !userdata.address || !userdata.bio || !userdata.first_name || !userdata.last_name || userdata.experience == "0") {
            let authData = localStorage.getItem('IncompleteProfile');
            if(!authData){
                document.getElementById("incompleteprofile").classList.add("active");
            }
        }
    }

    const IncompleteProfileClose = () => {
        localStorage.setItem('IncompleteProfile', JSON.stringify({
            user_name: userDetails.first_name,
            auth_role_id: userDetails.role_id,
        }));
        document.getElementById("incompleteprofile").classList.remove("active");
    }

    const pagepermission = async () => {
        let authData = localStorage.getItem('AuthData');
        if (authData) {
            try {
                const response = await http.get('api/userrole');
                if (!response.data.is_job_seeker) {
                    window.location.href = '/my-account-employer';
                }
            } catch (exception) {

            }
        }
    }

    const ProfilePic = () => {
        const choosedFile = document.getElementById("profilePicInput").files[0];
        setUserImage(choosedFile);
        const reader = new FileReader();
        reader.addEventListener('load', function () {
            document.getElementById("profilePic").src = reader.result;
            document.getElementById("picUpdate").style.display = "block";
        });
        reader.readAsDataURL(choosedFile);
    }

    const imageSave = async () => {
        const formData = new FormData();
        formData.append('user_id', userDetails.user_id);
        formData.append('email', userDetails.email);
        formData.append('image', userImage);
        try {
            let response = await http.post("api/image-upload", formData);
            if (response.data) {
                setUserImage(response.data);
                document.getElementById("picUpdate").style.display = "none";
            }
        } catch (exception) {
            if (exception.response && exception.response.data && exception.response.data.errors) {
                console.log(exception.response.data.errors);
            }
        }

    }

    const Download = async () => {
        const resumeobj = JSON.parse(downloadResume.resume);
        const resume = resumeobj[0];
        const formData = new FormData();
        formData.append('download_link', resume.download_link);
        formData.append('original_name', resume.original_name);

        try {
            const response = await http.post('api/downloadpdf', {
                download_link: resume.download_link
            });

            if (response) {
                const link = document.createElement('a');
                link.href = response.data.url;
                link.setAttribute('download', resume.original_name); //or any other extension
                document.body.appendChild(link);
                link.click();
            }

        } catch (exception) {

        }
    }

    const DownloadResume = () => {
        if (downloadResume) {
            return (
                <button type='button' className='altBtn ms-2' onClick={Download}>download</button>
            )
        } else {
            return "";
        }
    }

    const ResumeUpload = async () => {
        setSavingResume(true);
        let errors = {};
        let isValid = true;

        if (!resume) {
            isValid = false;
            errors.resume = "Please upload your resume.";
        } else {
            var extension = resume.name.split('.').pop();
            if (!["pdf", "docx", "doc"].includes(extension.toString().toLowerCase())) {
                isValid = false;
                errors.resume = "Only PDF/DOCX/DOC files are allowed.";
            }
        }

        if (isValid) {

            const formData = new FormData();
            formData.append('id', downloadResume.id);
            formData.append('user_id', userDetails.user_id);
            formData.append('first_name', userDetails.first_name);
            formData.append('last_name', userDetails.last_name);
            formData.append('email', userDetails.email);
            formData.append('phone', userDetails.phone);
            formData.append('resume', resume);

            try {
                let response = await http.post("api/userupdate", formData);

                setErrors(errors);
                setResume('');
                setResumeKey(resumeKey + 1);
                setSavingResume(false);

                http.get('api/edit-profile')
                    .then((response) => {
                        setUserDetails(response.data);
                        let resume = response.data;
                        if (resume['userResume'][0]) {
                            setDownloadResume(resume['userResume'][0]);
                        }
                    });

                document.getElementById("pdfSuccess").classList.add("active");

            } catch (exception) {

                if (exception.response && exception.response.data && exception.response.data.errors) {

                    setErrors(exception.response.data.errors);
                    setSavingResume(false);
                }
            }

        } else {
            setErrors(errors);
            setSavingResume(false);
        }

    }

    const DownloadCover = async () => {
        const coverobj = JSON.parse(downloadConverletter);
        const conver = coverobj[0];
        const formData = new FormData();
        formData.append('download_link', conver.download_link);
        formData.append('original_name', conver.original_name);

        try {
            const response = await http.post('api/downloadpdf', {
                download_link: conver.download_link
            });

            if (response) {
                const link = document.createElement('a');
                link.href = response.data.url;
                link.setAttribute('download', conver.original_name); //or any other extension
                document.body.appendChild(link);
                link.click();
            }

        } catch (exception) {

        }

    }

    const DownloadCoverLetter = () => {
        if (downloadConverletter) {
            return (
                <button type='button' className='altBtn ms-2' onClick={DownloadCover}>download</button>
            )
        } else {
            return "";
        }
    }

    const ConverletterUpload = async () => {
        setSavingConverletter(true);
        let errors = {};
        let isValid = true;

        if (!coverletter) {
            isValid = false;
            errors.coverletter = "Please upload your Conver Letter.";
        } else {
            var extension = coverletter.name.split('.').pop();
            if (!["pdf", "docx", "doc"].includes(extension.toString().toLowerCase())) {
                isValid = false;
                errors.coverletter = "Only PDF/DOCX/DOC files are allowed.";
            }
        }

        if (isValid) {
            const formData = new FormData();
            formData.append('cover_letter', coverletter);
            try {
                let response = await http.post("api/uploadcover", formData);
                console.log(response.data);

                setErrors(errors);
                setConverletter('');
                setConverletterkey(converletterkey + 1);
                setSavingConverletter(false);

                http.get('api/edit-profile')
                    .then((response) => {
                        setDownloadConverletter(response.data.cover_letter);
                    });

                document.getElementById("coverSuccess").classList.add("active");
            } catch (exception) {

                if (exception.response && exception.response.data && exception.response.data.errors) {

                    setErrors(exception.response.data.errors);
                    setSavingResume(false);
                }
            }
        } else {
            setErrors(errors);
            setSavingConverletter(false);
        }
    }

        /*upload resume file input name showing function*/
        ; (function (document, window, index) {
            var inputs = document.querySelectorAll('.inputfile');
            Array.prototype.forEach.call(inputs, function (input) {
                var label = input.nextElementSibling,
                    labelVal = label.innerHTML;

                input.addEventListener('change', function (e) {
                    var fileName = '';
                    if (this.files && this.files.length > 1)
                        fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
                    else
                        fileName = e.target.value.split('\\').pop();

                    if (fileName)
                        label.querySelector('span').innerHTML = fileName;
                    else
                        label.innerHTML = labelVal;
                });

                // Firefox bug fix
                input.addEventListener('focus', function () { input.classList.add('has-focus'); });
                input.addEventListener('blur', function () { input.classList.remove('has-focus'); });
            });
        }(document, window, 0));
    /*upload resume file input name showing function*/

    const deleteAccount = () => {
        document.getElementById("delete-account").classList.add("active");
    }

    const DeleteAccountClose = () => {
        document.getElementById("delete-account").classList.remove("active");
    }


    const RemoveAccount = async () => {
        try {
            const formData = new FormData();
            formData.append('email', userDetails.email);
            let response = await http.post("api/otp", formData);
            if (response && response.data) {
                document.getElementById("delete-account").classList.remove("active");
                document.getElementById("confirmation-password-field").classList.add("active");
            }
        } catch (exception) {
            if (exception.response && exception.response.data) {

            }
        }
    }
    const ConfirmationPasswordClose = () => {
        document.getElementById("confirmation-password-field").classList.remove("active");
    }
    const ConfirmationPassword = async () => {
        try {
            const formData = new FormData();
            formData.append('user_id', userDetails.user_id);
            formData.append('accountotpconfimation', AccountOTPConfimation);
            let response = await http.post("api/deleteaccount", formData);
            if (response && response.data) {
                localStorage.clear();
                localStorage.removeItem('AuthData');
                //window.location.href = '/';

                setUserId(userDetails.user_id);
                setShowPopup(true);
                setShowmessage(response.data.message);
                ConfirmationPasswordClose();
            }
        } catch (exception) {
            if (exception.response && exception.response.data) {
                setErrors({
                    accountotpconfimation: exception.response.data.accountotpconfimation
                });
            }
        }
    }

    const ConfirmationPasswordhandleSubmit = async(event) => {
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append('user_id', userDetails.user_id);
            formData.append('accountotpconfimation', AccountOTPConfimation);
            let response = await http.post("api/deleteaccount", formData);
            if (response && response.data) {
                localStorage.clear();
                localStorage.removeItem('AuthData');
                //window.location.href = '/';
                setUserId(userDetails.user_id);
                setShowPopup(true);
                setShowmessage(response.data.message);
                ConfirmationPasswordClose();
            }
        } catch (exception) {
            if (exception.response && exception.response.data) {
                setErrors({
                    accountpasswordconfimation: exception.response.data.accountpasswordconfimation
                });
            }
        }
    }

    return (
        <main>
            <section className="sectionPad myAccountCandidate" id="topInnerPage">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="mainTitle">
                                <h2>My Account</h2>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="glassCard userIntro">
                                <form className="zForm white">
                                    <div className="div85 text-center">
                                        <input type="file" name="profilePicInput" id="profilePicInput" accept="image/png, image/gif, image/jpeg" onChange={ProfilePic} />
                                        <label className="imgSq">
                                            <img src={userImage ? userImage : "/img/user.svg"} alt="img" id="profilePic" />
                                            <label htmlFor="profilePicInput" id="edit"><i className="fas fa-pencil-alt"></i></label>
                                        </label>
                                        <button type="button" id="picUpdate" className='mainBtn dNone' onClick={imageSave}>Save</button>
                                    </div>
                                </form>
                                <div className="text-center">
                                    <h3>{userDetails ? userDetails.first_name : ''} {userDetails ? userDetails.last_name : ''}</h3>
                                    {/* <p className="location"><i class="fas fa-map-marker-alt"></i>Serbia</p> */}
                                    <p className="designation">{userDetails ? userDetails.current_designation : ''}</p>
                                </div>
                                <h4>Bio</h4>
                                {userDetails ? userDetails.bio : '-na-'}
                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
                                <h4>Skills</h4>
                                <ul className='skills'>
                                    {userDetails && userDetails.skills.map(skill => <li>{skill.name}</li>)}
                                </ul>
                                {/* <ul className='skills'>
                                <li>php</li>
                                <li>Javascript</li>
                                <li>HTML</li>
                                <li>React</li>
                            </ul> */}
                                <a href="/edit-profile" className="mainBtn">Edit Profile</a>&nbsp;
                                {/* <a href="/edit-profile" className="altBtn">Sign Out</a> */}
                            </div>
                            <div className="glassCard">
                                <h4>Upload</h4>
                                <form action="" className="zForm white">
                                    <div className="inpGroup mb-3">
                                        <label htmlFor="fileResume">Upload Resume</label>

                                        {/* <input name="fileResume" type="file" accept=".pdf,.docx" key={resumeKey} id='fileResume' onChange={e => setResume(e.target.files[0])} /> */}


                                        <div class="fileInpWrapper">
                                            <input type="file" name="fileResume" id="fileResume" accept=".pdf,.docx" key={resumeKey} class="fileinp inputfile inputfile-1" data-multiple-caption="{count} files selected" onChange={e => setResume(e.target.files[0])} />
                                            <label class="file-inp-l" for="fileResume"><span>&nbsp; Choose File</span><i className="bi bi-cloud-arrow-up"></i></label>
                                        </div>


                                        <small>File must be of type .pdf or .docx and less than 4mb.</small>
                                    </div>
                                    <div className="text-danger">{errors.resume}</div>
                                    <button type="button" className='mainBtn' onClick={ResumeUpload} disabled={savingResume}>
                                        {savingResume &&
                                            <div className="spinner-grow spinner-grow-sm mr-5" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        }
                                        Upload
                                    </button>
                                    <DownloadResume />
                                </form>
                                <hr />
                                <h4>Cover Letter</h4>
                                <form action="" className="zForm white">
                                    <div className="inpGroup mb-3">
                                        <label htmlFor="fileCoverLetter">Upload Cover Letter</label>
                                        <div class="fileInpWrapper">
                                            <input type="file" name="fileCoverLetter" id="fileCoverLetter" accept=".pdf,.docx" key={converletterkey} class="fileinp inputfile inputfile-1" data-multiple-caption="{count} files selected" onChange={e => setConverletter(e.target.files[0])} />
                                            <label class="file-inp-l" for="fileCoverLetter"><span>&nbsp; Choose File</span><i className="bi bi-cloud-arrow-up"></i></label>
                                        </div>


                                        <small>File must be of type .pdf or .docx and less than 4mb.</small>
                                    </div>
                                    <div className="text-danger">{errors.coverletter}</div>
                                    <button type="button" className='mainBtn' onClick={ConverletterUpload} disabled={savingConverletter}>
                                        {savingConverletter &&
                                            <div className="spinner-grow spinner-grow-sm mr-5" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        }
                                        Upload
                                    </button>
                                    <DownloadCoverLetter />
                                </form>
                            </div>

                            <div className="account-deletion">
                                <button type='button' onClick={deleteAccount}>Remove Account</button>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="glassCard">
                                <h4>Educational Qualifications</h4>

                                {userDetails && userDetails.educationalQualifications.map(educationalQualification => <div className="userDetails">
                                    <div className="d-flex">
                                        <h5>{educationalQualification.institution}</h5>
                                        {/* <p>2013 Jul - 2017 Jun</p> */}
                                    </div>
                                    <p>{educationalQualification.study_field}</p>
                                </div>)}

                                {/* <div className="userDetails">
                                <div className="d-flex">
                                    <h5>Mahatma Gandhi University</h5>
                                    <p>2013 Jul - 2017 Jun</p>
                                </div>
                                <p>Bachelor of University - BTech, Computer Science</p>
                            </div>
                            <div className="userDetails">
                                <div className="d-flex">
                                    <h5>Calicut University</h5>
                                    <p>2017 Dec - 2018 Aug</p>
                                </div>
                                <p>Master of University - MTech, Computer Science</p>
                            </div> */}
                            </div>
                            <div className="glassCard">
                                <h4>Work Experience</h4>
                                {userDetails && userDetails.workExperiences.map(workExperience => <div className="userDetails">
                                    <div className="d-flex">
                                        <h5>{workExperience.company}</h5>
                                        {/* <p>2013 Jul - 2017 Jun</p> */}
                                    </div>
                                    <p>{workExperience.designation}</p>
                                </div>)}
                                {/* <div className="userDetails">
                                <div className="d-flex">
                                    <h5>Designer</h5>
                                    <p>From 2018 Sep</p>
                                </div>
                                <p>Auditgear Technologies</p>
                            </div>
                            <div className="userDetails">
                                <div className="d-flex">
                                    <h5>Frontend Developer</h5>
                                    <p>2017 Dec - 2018 Aug</p>
                                </div>
                                <p>Other Technologies</p>
                            </div> */}
                            </div>
                            <div className="glassCard">
                                <h4>Contact Info</h4>
                                <div className="userDetails">
                                    <div className="d-flex"><h5>Phone</h5></div>
                                    {userDetails ? <a href="tel:+919999999999">{userDetails.phone} (Mobile)</a> : ''}
                                    {userDetails ? <a href="tel:+919999999999">{userDetails.work_phone ? userDetails.work_phone + ' (Work)' : ''}</a> : ''}
                                </div>
                                <div className="userDetails">
                                    <div className="d-flex"><h5>Email</h5></div>
                                    <a href="mailto:johndoe@email.com">{userDetails ? userDetails.email : ''}</a>
                                </div>
                                <div className="userDetails">
                                    <div className="d-flex"><h5>Address</h5></div>
                                    <p>{userDetails ? userDetails.address : ''}</p>
                                </div>
                            </div>
                            <div className="glassCard">
                                <h4>Applied Status</h4>
                                {userDetails && userDetails.vacancyapplication.map(vacancyapplication =>
                                    <div className="userDetails">
                                        <div className="d-flex">
                                            <h5>{vacancyapplication[0].title}</h5>
                                            <p>{vacancyapplication[0].status}</p>
                                        </div>
                                        {/* <p>Auditgear Technologies</p>
                                        <p className="sal"><i class="fas fa-coins"></i>3 - 5 lakhs per Annum</p>
                                        <div dangerouslySetInnerHTML={{ __html: vacancyapplication[0].basic_qualification }} /> */}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Popupz title="Delete account" id="delete-account">
                <p>Do you want to delete the account?</p>
                <button type='button' className="altBtn me-1" onClick={RemoveAccount}>Yes</button>
                <button type='button' className='mainBtn ms-1' onClick={DeleteAccountClose}>No</button>
            </Popupz>
            <Popupz title="OTP Verification" id="confirmation-password-field">
                <form className="zForm white" onSubmit={ConfirmationPasswordhandleSubmit}>
                    <div className="inpGroup">
                        <label htmlFor="otp_confirmation">Enter OTP</label>
                        <input type="password" name="otp_confirmation" id='otp_confirmation' placeholder='OTP' onChange={(e) => setAccountOTPConfimation(e.target.value)} />
                        <div className="text-danger">{errors.accountotpconfimation}</div>
                    </div>
                    <div className="bottomSection">
                        <button type='button' className="altBtn me-1" onClick={ConfirmationPassword}>Confirm</button>
                        <button type='button' className="mainBtn me-1" onClick={ConfirmationPasswordClose}>Cancel</button>
                    </div>
                </form>
            </Popupz>
            <SuccessPopup
                message={showmessage}
                show={showPopup}
                vacancyId={userId}
                onClose={() => setShowPopup(false)}
            />
            <Popupza title="Upload Success" id="pdfSuccess">
                <p>PDF Uploaded Successfully</p>
            </Popupza>
            <Popupza title="Upload Success" id="coverSuccess">
                <p>Cover Letter Uploaded Successfully</p>
            </Popupza>
            <Popupza title="Incomplete Profile" id="incompleteprofile">
                <p>Please complete your profile to enhance your job search experience with Clockhash.</p>
                <a className='mainBtn mx-2' href='/edit-profile' type='button'>Edit profile</a>
                <a className='altBtn' type='button' onClick={() => { IncompleteProfileClose() }}>Ignore</a>
            </Popupza>
        </main>
    )
}

export default MyAccountCandidate