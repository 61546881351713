import React from 'react';
import ReactTags from 'react-tag-autocomplete';
import http from '../../httpService';
import Popupz from '../../components/Popupz';
import { useNavigate } from "react-router-dom";

function SuccessPopup(props) {
    let navigate = useNavigate();
    const CreatePost = () => {
        let path = `/my-account-employer`;
        navigate(path);
    }
    return (
        <>
            <Popupz title="Hire From Us" id="uploadResume" className={props.show ? 'active' : ''} onClose={props.onClose}>
                <div className="text-center">
                    <svg className='img-fluid mb-2' xmlns="http://www.w3.org/2000/svg" width="113.625" height="113.133" viewBox="0 0 113.625 113.133">
                        <g id="Icon_feather-check-circle" data-name="Icon feather-check-circle" transform="translate(-1.49 -1.43)" stroke='#00ff2a'>
                            <path id="Path_1341" data-name="Path 1341" d="M112.994,52.962v5.06A55,55,0,1,1,80.381,7.755" transform="translate(0 0)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                            <path id="Path_1342" data-name="Path 1342" d="M85,6,30,61.052l-16.5-16.5" transform="translate(27.998 8.024)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" />
                        </g>
                    </svg>
                    <p>{props.message}</p>
                    <a onClick={CreatePost} style={{ 'cursor': 'pointer' }} className='mainBtn'>Go to My Account</a>
                </div>
            </Popupz>
        </>
    )
}

class HireFromUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tags: [],
            suggestions: [],
            userDetails: [],
            popupid: undefined,
            showPopup: false,
            showmessage: undefined
        }

        this.reactTags = React.createRef();
    }

    componentDidMount() {
        this.getEditProfileDetails();
        this.pagepermission();
    }

    pagepermission = async () => {
        let authData = localStorage.getItem('AuthData');
        if (authData) {
            try {
                const response = await http.get('api/userrole');
                if (response.data.is_hirer) {
                    console.log("Hirer");
                } else if(response.data.is_job_seeker) {
                    window.location.href = "/my-account";
                } else {
                    window.location.href = "/my-account-employer";
                }
            } catch (exception) {

            }
        } else {
            window.location.href = "/";
        }
    }

    getEditProfileDetails = async () => {
        const response = await http.get('api/getskills');
        if (response.data.skill) {
            let details = response.data.skill;
            let skillformation = [];
            details.map((item, index) => {
                index = index + 1;
                let data = { id: index, name: item.name };
                skillformation.push(data);
            })
            this.setState({ suggestions: skillformation });
        }
    }

    onDelete(i) {
        const tags = this.state.tags.slice(0);
        tags.splice(i, 1);
        this.setState({ tags });
    }

    onAddition(tag) {
        const tags = [].concat(this.state.tags, tag);
        this.setState({ tags });
    }

    render() {
        const FilterSearch = async () => {
            let skills = this.state.tags.map(item => item.name);
            const formData = new FormData();
            formData.append('tags', skills);
            try {
                let response = await http.post("api/getskillprofile", formData);
                this.setState({
                    userDetails: Object.values(response.data.skill)
                })
            } catch (exception) {

                if (exception.response && exception.response.data) {
                    console.log(exception.response.data);
                }
            }
        }

        const RequestButton = async (e, user) => {
            const formData = new FormData();
            formData.append('jobseekerid', user.id);
            formData.append('designation', user.current_designation);
            try {
                let response = await http.post("api/requestprofile", formData);
                if (response.data) {
                    this.setState({
                        popupid: response.data.userid,
                        showPopup: true,
                        showmessage: response.data.message
                    })
                }
            } catch (exception) {

                if (exception.response && exception.response.data) {
                    console.log(exception.response.data);
                }
            }
        }

        return (
            <>
                <main>
                    <section id="topInnerPage" className="sectionPad hireFromUs">
                        <div className="container">
                            <div className="col-12">
                                <div className="mainTitle">
                                    <h2>Hire from us</h2>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="glassCard">
                                    <div className="tabWrapper">
                                        <div className="tabDiv active" id="development" >
                                            <div className='col-md-12'>
                                                <div className="zForm white">
                                                    <div className="inpGroup">
                                                        <ReactTags
                                                            ref={this.reactTags}
                                                            tags={this.state.tags}
                                                            suggestions={this.state.suggestions}
                                                            onDelete={this.onDelete.bind(this)}
                                                            onAddition={this.onAddition.bind(this)}
                                                            placeholderText='Enter the Skills looking for'
                                                        />
                                                    </div>
                                                </div>
                                                <div className="text-end">
                                                    <a className="mainBtn" type='button' onClick={FilterSearch} style={{ 'cursor': 'pointer' }}>Search</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="developers" className="sectionPad">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    {this.state.userDetails ? this.state.userDetails.map((item, index) => {
                                        return (<>
                                            <div className="glassCard">
                                                <div className="imgSection">
                                                    <img src={item.user.avatar ? item.user.avatar : "/img/user.svg"} alt="User" />
                                                </div>
                                                <div className="textSection">
                                                    {/* <h3>{item.user.name}</h3> */}
                                                    <p className="developer">{item.user.current_designation}</p>
                                                    <p className="desc">Total Years of Experience - {item.user.experience}</p>
                                                    {/* <p className="remote">This job provide remote work option</p>
                                                <ul className="iconDiv">
                                                    <li><i className="bi bi-geo-alt"></i>Serbia</li>
                                                    <li><i className="bi bi-calendar4"></i> Clockhash Member Since February 17, 2019</li>
                                                </ul>*/}
                                                    <p className="desc">
                                                        {item.user.bio}
                                                    </p>
                                                    <ul className="skills">
                                                        {item.skills ? item.skills.map(skill => <li><a>{skill.tag}</a></li>) : ""}
                                                    </ul>
                                                    <a type='button' className="mainBtn" id={item.user.id} onClick={(e) => RequestButton(e, item.user)}>Request View Profile</a>
                                                </div>
                                            </div>
                                        </>)
                                    }) : ""}
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <SuccessPopup
                    message={this.state.showmessage}
                    show={this.state.showPopup}
                    vacancyId={this.state.popupid}
                    onClose={() => this.setState({ showPopup: false })}
                />
            </>
        )
    }
}

export default HireFromUs;
